import Handsontable from 'handsontable';
import $ from 'jquery';
import afterChangeEventHanlderForSingleInput from './afterChangeEventHanlderForSingleInput';

export default (countryId, referenceProperties) => {
  const element = document.getElementById('gas-prices-table');

  if (!element) return;

  const inputData = JSON.parse($('#gas-prices-input-data').val());

  let countrySettings = {};
  switch (countryId) {
    case JSCONSTANT.saudi_arabia_id:
      countrySettings = {
        colWidths: [100, 100, 100],
        colHeaders: ['Year', 'Residential', 'Industrial'],
        columns: [
          {
            editor: false,
          },
          ...Array(2).fill({
            type: 'numeric',
            numericFormat: {
              pattern: '0,0.[00]',
            },
          }),
        ],
      };
      break;
    case JSCONSTANT.jordan_id:
      countrySettings = {
        colWidths: [100, 100, 100, 100],
        colHeaders: ['Year', 'LNG', 'Residential', 'Industrial'],
        columns: [
          {
            editor: false,
          },
          ...Array(3).fill({
            type: 'numeric',
            numericFormat: {
              pattern: '0,0.[00]',
            },
          }),
        ],
      };
      break;
    case JSCONSTANT.morocco_id:
      countrySettings = {
        colWidths: [100, 100, 100, 100],
        colHeaders: ['Year', 'Spain Exports', 'Domestic', 'Industrial'],
        columns: [
          {
            editor: false,
          },
          ...Array(3).fill({
            type: 'numeric',
            numericFormat: {
              pattern: '0,0.[00]',
            },
          }),
        ],
      };
      break;
    case JSCONSTANT.alaska_id:
    case JSCONSTANT.albania_id:
    case JSCONSTANT.australia_id:
    case JSCONSTANT.austria_id:
    case JSCONSTANT.australia_id:
    case JSCONSTANT.azerbaijan_id:
    case JSCONSTANT.bangladesh_id:
    case JSCONSTANT.belarus_id:
    case JSCONSTANT.canada_offshore_id:
    case JSCONSTANT.denmark_id:
    case JSCONSTANT.germany_id:
    case JSCONSTANT.greece_id:
    case JSCONSTANT.hungary_id:
    case JSCONSTANT.india_id:
    case JSCONSTANT.indonesia_id:
    case JSCONSTANT.iran_id:
    case JSCONSTANT.iraq_id:
    case JSCONSTANT.ireland_id:
    case JSCONSTANT.italy_id:
    case JSCONSTANT.kurdistan_id:
    case JSCONSTANT.malaysia_id:
    case JSCONSTANT.myanmar_id:
    case JSCONSTANT.netherlands_id:
    case JSCONSTANT.norway_id:
    case JSCONSTANT.philippines_id:
    case JSCONSTANT.pakistan_id:
    case JSCONSTANT.poland_id:
    case JSCONSTANT.romania_id:
    case JSCONSTANT.thailand_id:
    case JSCONSTANT.turkmenistan_id:
    case JSCONSTANT.uk_id:
    case JSCONSTANT.uzbekistan_id:
    case JSCONSTANT.venezuela_id:
    case JSCONSTANT.vietnam_id:
    case JSCONSTANT.malaysia_thailand_jda_id:
    case JSCONSTANT.china_id:
      countrySettings = {
        colWidths: [100, 100],
        colHeaders: ['Year', 'Price'],
        columns: [
          {
            editor: false,
          },
          ...Array(1).fill({
            type: 'numeric',
            numericFormat: {
              pattern: '0,0.[00]',
            },
          }),
        ],
      };
      break;
    case JSCONSTANT.israel_id:
      countrySettings = {
        colWidths: [100, 100, 100, 100, 100],
        colHeaders: ['Year', 'Domestic', 'Egypt', 'Jordan', 'LNG'],
        columns: [
          {
            editor: false,
          },
          ...Array(4).fill({
            type: 'numeric',
            numericFormat: {
              pattern: '0,0.[00]',
            },
          }),
        ],
      };
      break;
    case JSCONSTANT.mozambique_id:
      countrySettings = {
        colWidths: [100, 150, 150],
        colHeaders: ['', 'Domestic Market', 'Export Market'],
        columns: [
          {
            editor: false,
          },
          ...Array(2).fill({
            type: 'numeric',
            numericFormat: {
              pattern: '0,0.[00]',
            },
          }),
        ],
      };
      break;
    default:
      countrySettings = {
        colWidths: [100, 100, 100, 100],
        colHeaders: ['Year', 'Domestic', 'Egypt', 'Jordan'],
        columns: [
          {
            editor: false,
          },
          ...Array(3).fill({
            type: 'numeric',
            numericFormat: {
              pattern: '0,0.[00]',
            },
          }),
        ],
      };
      break;
  }

  const settings = {
    data: inputData,
    afterChange: function (event) {
      const propertyId = referenceProperties.gasPriceInput;

      afterChangeEventHanlderForSingleInput(propertyId, this.getData(), ':');
    },
    ...countrySettings,
  };

  let table = new Handsontable(element, settings);
  window.handsontableInstances.push(table);

  $('a[data-toggle="tab"][href="#financial-considerations"]').on(
    'shown.bs.tab',
    (event) => table.render(),
  );
};
