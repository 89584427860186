import $ from 'jquery';
import hideAlert from './hideAlert';
import showAlert from './showAlert';

export default function (ev) {
  hideAlert('.admin.alert');
  let btn = $(ev.target);
  let form = $(btn).closest('form');

  if ($(btn).hasClass('disabled')) {
    return;
  }

  $(btn).addClass('disabled');

  for (let instance in CKEDITOR.instances) {
    CKEDITOR.instances[instance].updateElement();
  }

  let method = $(form).attr('method').toUpperCase();
  let data = $(form).serializeArray();

  $(form)
    .find('input[type="checkbox"][name^="property"]')
    .each(function (i, c) {
      if (!$(c).prop('checked')) {
        data.push({
          name: $(c).prop('name'),
          value: 'false',
        });
      }
    });

  $.ajax({
    url: $(form).attr('action'),
    method: method,
    data: data,
    success: function (response) {
      $(btn).removeClass('disabled');
      showAlert(response.message, 'info');
      let runCalculationsButton = $(form).find('.save-button.run-calc');

      if (response.enable_calc_button) {
        runCalculationsButton.removeClass('disabled');
        let run_calculations_text =
          '<span>' + I18n.t('common.run_calculations') + '</span>';
        runCalculationsButton.html(run_calculations_text);
      } else {
        runCalculationsButton.addClass('disabled');
        runCalculationsButton.empty();
        runCalculationsButton.text(
          I18n.t('common.calculations_no_prediction_data'),
        );
      }

      setTimeout(hideAlert, 3000, '.admin.alert');
    },
    error: function (response) {
      $(btn).removeClass('disabled');
      showAlert(response.responseJSON.message, 'danger');
    },
  });

  // for static field properties page
  if ($(form).attr('data-page') == 'static_field') {
    if ($('#map_picture').length) {
      let data = new FormData();

      if ($('#map_picture')[0].files.length) {
        data.append('map_picture', $('#map_picture')[0].files[0]);
      }

      $.ajax({
        url: $(form).attr('data-url-map-picture'),
        method: 'PATCH',
        data: data,
        processData: false,
        contentType: false,
      });
    }
  }

  // for country properties page
  if ($(form).attr('data-page') == 'static_country') {
    let data = new FormData();

    $(form)
      .find($("input[type='file']"))
      .each(function (index) {
        if ($(this)[0].files.length) {
          data.append($(this).attr('id'), $(this)[0].files[0]);
        }
      });

    // check formdata is not blank
    let isFormDataNotBlank = [];
    let keys = data.keys();
    do {
      isFormDataNotBlank.push('key');
    } while (!keys.next().done);

    if (isFormDataNotBlank.length >= 2) {
      $.ajax({
        url: $(form).attr('data-url-update-file'),
        method: 'PATCH',
        data: data,
        processData: false,
        contentType: false,
      });
    }
  }

  // for all countries, update images for enhanced report
  let inputFiles = $('.report_pictures input[type="file"]')
    .map(function () {
      if ($(this).val() != '') {
        return $(this);
      }
    })
    .get();

  if (inputFiles.length) {
    let imageData = new FormData();
    $('.report_pictures input[type="file"]').each(function (index, obj) {
      if (obj.files.length) {
        imageData.append(obj.id, obj.files[0]);
      }
    });

    $.ajax({
      url: $(form).attr('data-url-enhanced-map-picture'),
      method: 'PATCH',
      data: imageData,
      processData: false,
      contentType: false,
    });
  }
}
