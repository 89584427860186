import $ from 'jquery';
import 'bootstrap-datepicker';

export default function formatProductionYear() {
  $('.production_start_date').change(function () {
    var date_change = moment($('.production_start_date').val(), 'MM/DD/YYYY');
    var date_obj = date_change.toDate();
    var day = date_obj.getDate();
    var month = date_obj.getMonth() + 1;
    var year = date_obj.getFullYear();

    if (day.toString() != '1') {
      alert('Day of Production Start Date should be 1!');
      $('.production_start_date').datepicker(
        'setDate',
        new Date(month.toString() + '/01/' + year.toString()),
      );
    }
  });
}
