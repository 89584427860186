import $ from 'jquery';
export default (ev) => {
  let btn = $('#run-calculation-btn');
  let run_calculations = true;

  if ($(btn).hasClass('disabled')) {
    return;
  }

  // it is not the best way to check changes
  // but currently we setup 'onbeforeunload' if any change is made
  if (window.onbeforeunload != null) {
    run_calculations = confirm(
      'You have unsaved changes. Are you sure you want to run calculations?',
    );
  }

  if (!run_calculations) return;

  $('#confirm-calculation-modal').modal();
};
