import Handsontable from 'handsontable';
import $ from 'jquery';
export default (countryId) => {
  let element = document.getElementById('machine-utilization-gas-table');
  if (!element) return;

  const inputData = JSON.parse($('#machine-utilization-gas-data').val());
  let headers;
  if (countryId === JSCONSTANT.argentina_id) {
    headers = [
      'Machinery',
      'In-field Gen.',
      'Purchased Elec.',
      'Emissions Factor',
    ];
  } else {
    headers = ['Machinery', 'Production', 'Processing', 'Emissions Factor'];
  }

  const settings = {
    data: inputData,
    colWidths: Array(4).fill(200),
    colHeaders: headers,
    columns: [
      {
        type: 'text',
      },
      {
        type: 'numeric',
        numericFormat: {
          pattern: '0,0.[00]',
        },
      },
      {
        type: 'numeric',
        numericFormat: {
          pattern: '0,0.[00]',
        },
      },
      {
        type: 'numeric',
        numericFormat: {
          pattern: '0,0.[00000000]',
        },
      },
    ],

    afterChange: function (event) {
      let newData = this.getData()
        .map((subArray) => subArray.join(':'))
        .join(';');
      $(`#machine-utilization-gas`).val(newData);
    },
  };

  let table = new Handsontable(element, settings);

  $('a[data-toggle="tab"][href="#ghg-asset-inputs"]').on(
    'shown.bs.tab',
    (event) => table.render(),
  );
};
