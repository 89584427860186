import $ from 'jquery';
import '@selectize/selectize';

export default () => {
  $('select.country-select').selectize({
    plugins: ['remove_button', 'clear_button'],
  });

  $('.save-button.as-link').click(function () {
    return (window.location = $(this).data('link'));
  });
};
