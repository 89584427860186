import $ from 'jquery';
import hideAlert from '../common/hideAlert';
import showAlert from '../common/showAlert';

export default (ev) => {
  let btn = $(ev.target).parent();

  var file_data = $('#import_template_core').prop('files')[0];
  var form_data = new FormData();
  form_data.append('file', file_data);

  $.ajax({
    url: $(btn).data('url'),
    method: 'POST',
    data: form_data,
    dataType: 'json',
    cache: false,
    contentType: false,
    processData: false,
    success: function (response) {
      showAlert('Core template has been updated!', 'info');
      setTimeout(hideAlert, 3000, '.admin.alert');
    },
    error: function (response) {
      console.log(response);
      showAlert('Please select the core template!', 'danger');
      setTimeout(hideAlert, 3000, '.admin.alert');
    },
  });
};
