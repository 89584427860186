import Handsontable from 'handsontable';
import $ from 'jquery';
import afterChangeEventHanlderForSingleInput from './afterChangeEventHanlderForSingleInput';

export default (country_id, property_name, referenceProperties) => {
  let element, inputData, settings;

  if (
    $('#eor-uplift-by-value-input-data').length === 0 &&
    $('#eor-uplift-by-percent-input-data').length === 0
  ) {
    return;
  }

  if (property_name === 'by-value') {
    element = document.getElementById('eor-uplift-by-value-table');
    inputData = JSON.parse($('#eor-uplift-by-value-input-data').val());
  } else {
    element = document.getElementById('eor-uplift-by-percent-table');
    inputData = JSON.parse($('#eor-uplift-by-percent-input-data').val());
  }

  if (!element) return;

  if (JSCONSTANT.country_has_eor_uplift.includes(country_id)) {
    settings = {
      data: inputData,
      colHeaders: ['Year', 'Base Case'],
      columns: [
        {
          editor: false,
        },
        {
          type: 'text',
          placeholder: 'NA',
        },
      ],
      maxRows: JSCONSTANT.handsontable.wellScheduleRows,
      afterChange: function (event) {
        let propertyId;
        if (property_name === 'by-value') {
          propertyId = referenceProperties.eORUpliftbyValue;
        } else {
          propertyId = referenceProperties.eORUpliftbyPercent;
        }

        afterChangeEventHanlderForSingleInput(propertyId, this.getData(), ':');
      },
    };
  }

  if (property_name === 'by-value') {
    let table = new Handsontable(element, settings);
    window.handsontableInstances.push(table);

    $('a[data-toggle="tab"][href="#development-schedule"]').on(
      'shown.bs.tab',
      (event) => table.render(),
    );
  } else {
    let table = new Handsontable(element, settings);
    window.handsontableInstances.push(table);

    $('a[data-toggle="tab"][href="#development-schedule"]').on(
      'shown.bs.tab',
      (event) => table.render(),
    );
  }
};
