import enqueueCalculations from '../country_page/enqueueCalculations';
import syncBackendInputData from './syncBackendInputData';
import setUnloadConfirmationOnFormChanged from '../country_page/setUnloadConfirmationOnFormChanged';
import setUnloadConfirmation from '../country_page/setUnloadConfirmation';
import submitFieldsForm from './submitFieldsForm';
import ignoreUnload from '../country_page/ignoreUnload';
import Sortable, { MultiDrag, Swap } from 'sortablejs';

import $ from 'jquery';
import '@selectize/selectize';
import 'ckeditor4';

Sortable.mount(new MultiDrag(), new Swap());

const sortableTextInputInitialize = () => {
  const columns = document.querySelectorAll(
    '[data-name$="_group"] [class*="column-wrapper"]',
  );

  window.sortableList ??= [];
  for (const col of columns) {
    const orderInput = document.querySelector(
      `[data-name$="_group"] input[name="input_order[${col.id}]"]`,
    );
    orderInput.value = JSON.stringify(
      [...col.querySelectorAll('input[type="hidden"]')].map(
        (input) => input.id,
      ),
    );
    window.sortableList.push(
      new Sortable.create(col, {
        animation: 250,
        easing: 'cubic-bezier(1, 0, 0, 1)',
        handle: '.drag-handle',
        preventOnFilter: true,
        draggable: '.property.list-item',

        forceFallback: false,

        ghostClass: 'sortable-ghost',
        chosenClass: 'sortable-chosen',
        dragClass: 'sortable-drag',
        fallbackClass: 'sortable-fallback',
        fallbackOnBody: false,
        fallbackTolerance: 0,

        dragoverBubble: false,
        removeCloneOnHide: true,
        emptyInsertThreshold: 5,
        onStart: function (evt) {
          const ckeditorInstaceName = evt.item.querySelector('textarea').id;
          CKEDITOR.instances[ckeditorInstaceName].destroy();
        },
        onEnd: function (evt) {
          const ckeditorInstaceName = evt.item.querySelector('textarea').id;
          CKEDITOR.replace(ckeditorInstaceName, { height: 120 });
          orderInput.value = JSON.stringify(
            [...col.querySelectorAll('input[type="hidden"]')].map(
              (input) => input.id,
            ),
          );
        },
      }),
    );
  }
};

export default (country_id, type, asset, tab) => {
  let url;
  url = Routes['admin_country_asset_static_' + type + '_properties_path'](
    country_id,
    asset,
  );

  try {
    const ckEditors = Object.keys(CKEDITOR.instances);
    ckEditors.forEach((ckeditorInstanceName) => {
      CKEDITOR.instances[ckeditorInstanceName].destroy();
    });
  } catch (error) {
    console.log(error);
  }

  $.ajax({
    url: url,
    method: 'GET',
    data: {
      active_tab: tab,
    },
    success: function (field_form) {
      setUnloadConfirmation(false);
      const form = $('.row.asset-fields-form');
      $(form).html(field_form);
      $(form)
        .find('.save-button[data-type="submit"]')
        .on('click', submitFieldsForm);
      $(form)
        .find('.save-button[data-type="enqueue"]')
        .on('click', enqueueCalculations);
      $(form)
        .find('.save-button[data-type="sync-backend-data"]')
        .on('click', syncBackendInputData);
      $('select.property-selectize').selectize({
        placeholder: 'Select value...',
      });
      $('select.dependent_comp_assets').selectize({
        placeholder: I18n.t('js.customer.select'),
      });
      ignoreUnload($(form).find('.save-button[data-type="submit"]'));
      setUnloadConfirmationOnFormChanged(form);
      $('.asset-fields-form').removeClass('ready-to-load loading');
      const type = $('.hidden.asset-type').attr('data-asset-type');
      Admin.StaticFieldPropertyPage(country_id, type);

      sortableTextInputInitialize();
    },
  });
};
