import Handsontable from 'handsontable';
import $ from 'jquery';
import afterChangeEventHanlderForMultipleInput from './afterChangeEventHanlderForMultipleInput';

export default (referenceProperties) => {
  const element = document.getElementById('basin-oil-prod-exported-table');
  if (!element) return;

  const inputData = JSON.parse($('#basin-oil-prod-exported-input-data').val());
  const settings = {
    data: inputData,
    columns: [
      {
        data: 'year',
        type: 'text',
      },
      {
        data: 'australOilProdutionExported',
        type: 'numeric',
        numericFormat: {
          pattern: '0,0.[00000]',
        },
      },
      {
        data: 'cuyanaOilProdutionExported',
        type: 'numeric',
        numericFormat: {
          pattern: '0,0.[00000]',
        },
      },
      {
        data: 'golfoSanJorgeOilProdutionExported',
        type: 'numeric',
        numericFormat: {
          pattern: '0,0.[00000]',
        },
      },
      {
        data: 'neuquinaOilProdutionExported',
        type: 'numeric',
        numericFormat: {
          pattern: '0,0.[00000]',
        },
      },
      {
        data: 'noroesteOilProdutionExported',
        type: 'numeric',
        numericFormat: {
          pattern: '0,0.[00000]',
        },
      },
    ],
    rowHeaders: false,
    colWidths: [70, 100, 100, 130, 100, 100],
    colHeaders: [
      'Year',
      'Austral',
      'Cuyana',
      'Golfo San Jorge',
      'Neuquina',
      'Noroeste',
    ],

    afterChange: function (event) {
      const temp_result = {
        australOilProdutionExported: [],
        cuyanaOilProdutionExported: [],
        golfoSanJorgeOilProdutionExported: [],
        neuquinaOilProdutionExported: [],
        noroesteOilProdutionExported: [],
      };
      afterChangeEventHanlderForMultipleInput(
        temp_result,
        this.getSourceData(),
        referenceProperties,
      );
    },
  };

  let table = new Handsontable(element, settings);
  window.handsontableInstances.push(table);

  $('a[data-toggle="tab"][href="#taxes"]').on('shown.bs.tab', (event) =>
    table.render(),
  );
};
