import Handsontable from 'handsontable';
import $ from 'jquery';
export default () => {
  let element = document.getElementById('global-ghg-inputs');
  const inputData = JSON.parse($('#global-ghg-properties-data').val());

  const settings = {
    data: inputData,
    colWidths: [100, 150, 150, 150],
    colHeaders: ['Type', 'Stoichiometric_Factor', 'GWP', 'Gas_Density_g_ft3'],
    columns: [
      {
        type: 'text',
      },
      ...Array(3).fill({
        type: 'numeric',
        numericFormat: {
          pattern: '0,0.[00000000]',
        },
      }),
    ],

    afterChange: function (event) {
      let newData = this.getData()
        .map((subArray) => subArray.join(':'))
        .join(';');
      $(`#global-ghg-properties`).val(newData);
    },
  };

  new Handsontable(element, settings);
};
