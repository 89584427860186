import $ from 'jquery';
export default function importTypeCurve() {
  $('.btn-import-type-curve').on('click', function () {
    const type = this.dataset.type;
    if ($(`#type-curve-${type}`).get(0).files.length === 0) {
      return $(this)
        .parent()
        .find('.import-error')
        .html('CSV file is required');
    } else {
      $(this).parent().find('.import-error').html('');
      const marker = $(`.type-curve-${type}`).first();
      const field = marker.attr('field_id');
      const country = marker.attr('country_id');
      const data = new FormData();
      data.append('file', $(`#type-curve-${type}`).get(0).files[0]);
      data.append('type', type);

      const asset_type = JSCONSTANT.country_have_block_manual_input.includes(
        parseInt(country),
      )
        ? 'block'
        : 'field';

      $.ajax({
        url: Routes.import_type_curve_admin_country_asset_path(country, field, {
          asset_type,
        }),
        type: 'POST',
        data,
        processData: false,
        contentType: false,
        dataType: 'script',
        success() {
          $('html, body').animate({ scrollTop: 0 }, 300);
        },
      });
    }
  });
}
