import Handsontable from 'handsontable';
import $ from 'jquery';
export default () => {
  let element = document.getElementById('in-field-fuel-consumption-table');
  if (!element) return;

  const inputData = JSON.parse($('#in-field-fuel-consumption-data').val());
  const settings = {
    data: inputData,
    colWidths: [50, ...Array(5).fill(150), 200],
    colHeaders: [
      'Year',
      'Natural Gas (MMcf/d)',
      'Diesel (gal/d)',
      'Crude (bbl/d)',
      'Coal (lbs/d)',
      'Biofuel (gal/d)',
      'Total Purchased Elec. (MWh)',
    ],
    columns: [
      { type: 'numeric' },
      ...Array(6).fill({
        type: 'numeric',
        numericFormat: {
          pattern: '0,0.[00000000]',
        },
      }),
    ],

    afterChange: function (event) {
      let newData = this.getData()
        .map((subArray) => subArray.join(':'))
        .join(';');
      $(`#in-field-fuel-consumption`).val(newData);
    },
  };

  let table = new Handsontable(element, settings);

  $('a[data-toggle="tab"][href="#ghg-asset-inputs"]').on(
    'shown.bs.tab',
    (event) => table.render(),
  );
};
