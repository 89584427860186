import exportCSV from './exportCSV';
import tcUpdate from './tcUpdate';
import requestAction from './requestAction';
import enqueueCalculations from './enqueueCalculations';
import confirmCalculations from './confirmCalculations';
import importCompanies from './importCompanies';
import importCompaniesConfirm from './importCompaniesConfirm';
import submitFieldsForm from '../common/submitFieldsForm';
import addOpenPageListener from '../common/addOpenPageListener';
import setUnloadConfirmationOnFormChanged from './setUnloadConfirmationOnFormChanged';
import ignoreUnload from './ignoreUnload';
import setWindowOnErrorHandler from '../common/setWindowOnErrorHandler';
import setImportStaticFieldPropertiesURL from './setImportStaticFieldPropertiesURL';
import importStaticFieldProperties from './importStaticFieldProperties';
import importADTracker from './importADTracker';
import hideAlert from '../common/hideAlert';
import staticCountryProperty from '../country_properties_page';
import importPipelineSegmentThroughput from './importPipelineSegmentThroughput';
import importCoreTemplateCountry from './importCoreTemplateCountry';
import generateCoreReportCountry from './generateCoreReportCountry';
import downloadCoreReportCountry from './downloadCoreReportCountry';

import $ from 'jquery';
import '@selectize/selectize';

export default () => {
  addOpenPageListener();
  setWindowOnErrorHandler();
  $('.admin.alert.hidden').hide().removeClass('hidden');

  setUnloadConfirmationOnFormChanged($('form.admin.form'));
  ignoreUnload($('form.admin.form').find('.save-button[data-type="submit"]'));
  $('.update-field-property.save-button[data-type="submit"]').on(
    'click',
    submitFieldsForm,
  );

  $('select.country-select').selectize({
    onInitialize: function () {
      if (this.getValue() === '') return;

      const self = this;
      setTimeout(() => {
        const submitBtn = self.$wrapper
          .parents('.country-search')
          .find('.show-field-btn');
        submitBtn.attr('data-country', this.getValue());
        submitBtn.click();
      }, 500);
    },
    placeholder: 'Select ...',
    onChange: (value) => {
      $('.save-button.filter-button[data-action="show-country-fields"]').attr(
        'data-country',
        value,
      );
      $('.save-button.filter-button[data-action="import-country-fields"]').attr(
        'data-country',
        value,
      );
      $('.save-button.filter-button.backup-btn').attr('data-country', value);
      setImportStaticFieldPropertiesURL(value);
      $('label.import-error').hide();
    },
  });

  $('.import-csv-container form').on('submit', importStaticFieldProperties);
  $('#import_csv').on('change', function () {
    $('label.import-error').hide();
  });

  $('import-ma-container form').on('submit', importADTracker);
  $('#import_ma').on('change', function () {
    $('label.import-error').hide();
  });

  $('.save-button.filter-button[data-action="show-country-fields"]').on(
    'click',
    function (ev) {
      hideAlert($('.alert').not('.admin'));
      const btn = $(this);
      const country = btn.attr('data-country');

      if (country == '0') return;

      $('.country-fields-form').addClass('ready-to-load loading');
      const url = '/admin/countries/' + country + '/static_country_properties';

      $.ajax({
        url: url,
        method: 'GET',
        success: function (field_form) {
          btn.attr('data-country', '0');
          const form = $('.row.country-fields-form').first();
          $('html, body').animate(
            {
              scrollTop: form.offset().top - 20,
            },
            600,
          );

          form.html(field_form);
          form
            .find('.save-button[data-type="submit"]')
            .on('click', submitFieldsForm);
          form
            .find('.save-button[data-type="run-calculation"]')
            .on('click', confirmCalculations);
          form
            .find('.save-button[data-type="import-companies"]')
            .on('click', importCompanies);
          form
            .find('.save-button[data-type="enqueue"]')
            .on('click', enqueueCalculations);
          form
            .find('.save-button[data-type="import"]')
            .on('click', importCompaniesConfirm);
          form
            .find('.save-button[data-type="tc-update"]')
            .on('click', tcUpdate);
          form
            .find('.save-button[data-type="request"]')
            .on('click', requestAction);
          form.find('.save-button[data-type="export"]').on('click', exportCSV);
          $('select.property-selectize').selectize({
            placeholder: 'Select value...',
          });
          $('.ckeditor').each(function () {
            const editorConfig = $(this).data('ckeditor');
            CKEDITOR.replace(this.id, {
              customConfig: '/ckeditor/config.js',
              ...editorConfig,
            });
          });
          addOpenPageListener();
          ignoreUnload(form.find('.save-button[data-type="submit"]'));
          setUnloadConfirmationOnFormChanged(form);
          $('.country-fields-form').removeClass('ready-to-load loading');
          form
            .find('.save-button[data-type="pipe-throughput"]')
            .on('click', importPipelineSegmentThroughput);

          $('#import_template_core_btn').on('click', importCoreTemplateCountry);
          $('#download_last_template').on('click', downloadCoreReportCountry);
          $('#generate_report_core_btn').on('click', generateCoreReportCountry);
          $('#download_report_core_file').on(
            'click',
            downloadCoreReportCountry,
          );

          staticCountryProperty(country);
        },
      });
    },
  );
};
