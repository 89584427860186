import Handsontable from 'handsontable';
import $ from 'jquery';
export default () => {
  let element = document.getElementById('renewable-energy-projects-table');
  if (!element) return;

  const inputData = JSON.parse($('#renewable-energy-projects-data').val());
  const settings = {
    data: inputData,
    colWidths: [...Array(3).fill(120)],
    colHeaders: ['Project', 'Start Year', 'Capacity (MWh)'],
    columns: [
      { type: 'text' },
      { type: 'numeric' },
      {
        type: 'numeric',
        numericFormat: {
          pattern: '0,0.[00000000]',
        },
      },
    ],

    afterChange: function (event) {
      let newData = this.getData()
        .map((subArray) => subArray.join(':'))
        .join(';');
      $(`#renewable-energy-projects`).val(newData);
    },
  };

  let table = new Handsontable(element, settings);

  $('a[data-toggle="tab"][href="#ghg-asset-inputs"]').on(
    'shown.bs.tab',
    (event) => table.render(),
  );
};
