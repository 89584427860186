import Handsontable from 'handsontable';
import $ from 'jquery';
const afterChangeEventHanlderForParticipationInput = (updatedData) => {
  const newValue = updatedData
    .map((subArray) => subArray.join(JSCONSTANT.specialCharCell))
    .join(JSCONSTANT.specialCharRow);

  $('#participations_field').val(newValue);
};

const COMPANY_UNIQUE_INDEXES = [
  ...Array(JSCONSTANT.participation_columns).keys(),
].map((idx) => idx * 2 + 3);
const COMPANY_COLUMN_INDEXES = COMPANY_UNIQUE_INDEXES.flatMap((idx) => [
  idx,
  idx + 1,
]);

export default () => {
  const element = document.getElementById('participant-table');
  if (!element) return;

  const inputData = JSON.parse($('#participation-input-data').val());
  const companyData = JSON.parse($('#company-data').val());

  const columns = [
    {
      type: 'autocomplete',
      source: [
        'Asset Deal',
        'Asset Swap',
        'Bankruptcy/Failure to Meet Obligations',
        'Change in Operator',
        'Company Exit',
        'Contract Conversion',
        'Corporate M&A',
        'Deal Failure',
        'Discovered Resource Opportunity Award',
        'Expropriation',
        'Farm-in/out',
        'License Award',
        'License End',
        'License Extension',
        'Name Change',
        'Other',
        'Package Deal',
        'Partial Relinquishment',
        'Project Sanction',
        'Relinquishment',
        'Unitization',
      ],
      placeholder: 'N/A',
      strict: true,
      allowInvalid: false,
    },
    {
      type: 'date',
      dateFormat: 'DD-MMM-YYYY',
      datePickerConfig: {
        yearRange: 50,
      },
      validator: function (value, callback) {
        const data = this.instance.getDataAtRow(this.row);
        for (const idx of COMPANY_COLUMN_INDEXES) {
          if (!value && data[idx]) {
            return callback(false);
          }
        }

        return callback(true);
      },
      allowInvalid: true,
    },
    {
      type: 'numeric',
      numericFormat: {
        pattern: '0,0.[00]',
      },
      placeholder: 'N/A',
    },
    ...Array(JSCONSTANT.participation_columns)
      .fill([
        {
          type: 'autocomplete',
          source: companyData,
          placeholder: 'N/A',
          validator: function (value, callback) {
            const data = this.instance.getDataAtRow(this.row);
            if (!value && !data[this.col + 1]) return callback(true);
            if (!companyData.includes(value)) return callback(false);
            for (const idx of COMPANY_UNIQUE_INDEXES) {
              if (idx === this.col) continue;

              if (data[idx] === value) return callback(false);
            }
            callback(true);
          },
        },
        {
          type: 'numeric',
          numericFormat: {
            pattern: '0,0.[00]',
          },
          placeholder: 'N/A',
          validator: function (value, callback) {
            if (!value) return callback(true);
            const currentData = this.instance.getDataAtRow(this.row);
            currentData[this.col] = value;
            const pctSum = currentData
              .slice(4, -1)
              .filter((pctValue, idx) => pctValue && idx % 2 == 0)
              .map((value) => parseFloat(value))
              .reduce((a, b) => a + b);
            const roundedPctSum =
              Math.round((pctSum + Number.EPSILON) * 10_000) / 10_000;
            callback(roundedPctSum === 100);
          },
          allowInvalid: true,
        },
      ])
      .flat(),
    {
      type: 'text',
    },
  ];

  const settings = {
    licenseKey: 'non-commercial-and-evaluation',
    data: inputData,
    colHeaders: [
      'Event',
      'Effective Date',
      'Block Acreage (km2)',
      ...[...Array(JSCONSTANT.participation_columns).keys()]
        .map((idx) => [
          idx === 0 ? 'Operator' : `Participant #${idx + 1}`,
          '% stake',
        ])
        .flat(),
      'Comment',
    ],
    colWidths: [
      150,
      100,
      150,
      ...[...Array(JSCONSTANT.participation_columns).keys()]
        .map((_idx) => [130, 70])
        .flat(),
      150,
    ],
    columns,
    afterChange(changeEvents) {
      if (!changeEvents || changeEvents.length === 0) {
        return;
      }
      afterChangeEventHanlderForParticipationInput(this.getData());

      const rowIdxes = changeEvents.map((changeEvent) => changeEvent[0]);

      this.validateRows([...new Set(rowIdxes)]);
      const newValue = this.getData()
        .map((subArray) => subArray.join(':'))
        .join(';');
      $('#participation-input-data').val(newValue);
    },
    afterLoadData() {
      this.validateCells((valid) => {
        console.log('Done validating');
        console.log(valid);
      });
      afterChangeEventHanlderForParticipationInput(this.getData());
    },
  };

  let table = new Handsontable(element, settings);

  window.handsontableInstances.push(table);

  $('a[data-toggle="tab"][href="#participation"]').on('shown.bs.tab', (event) =>
    table.render(),
  );
};
