import {
  StaticGlobalPage,
  CountryPage,
  ManualInputPage,
  StaticFieldPropertyPage,
  NewAssetPage,
  initClientDownloadLimit,
  CountryInputPage,
  JenkinsBuildPage,
} from './admin/index';
import './channels/index';
import { Routes } from './routes';
import 'ckeditor4';
import './bootstrap.scss';

window.Admin = {
  StaticGlobalPage,
  CountryPage,
  ManualInputPage,
  StaticFieldPropertyPage,
  NewAssetPage,
  initClientDownloadLimit,
  CountryInputPage,
  JenkinsBuildPage,
};

window.I18n = {
  t: (key) => key,
};
window.Routes = Routes;
window.$ = require('jquery');
window.jQuery = require('jquery');
require('bootstrap3/dist/js/bootstrap.min');

// Support component names relative to this directory:
const componentRequireContext = require.context('components', true);
const ReactRailsUJS = require('react_ujs');
ReactRailsUJS.useContext(componentRequireContext);
