import $ from 'jquery';
import createCorporateTaxRateTable from './createCorporateTaxRateTable';
import createExportTaxesOilTable from './createExportTaxesOilTable';
import createExportTaxesGasTable from './createExportTaxesGasTable';
import createOilProductionExportedTable from './createOilProductionExportedTable';
import createGasProductionExportedTable from './createGasProductionExportedTable';
import createProvincialSalesTaxTable from './createProvincialSalesTaxTable';
import createCitTable from './createCitTable';
import createGasPricesTable from './createGasPricesTable';
import createOilPriceOverridesTable from './createOilPriceOverridesTable';
import createBasinOilProdExportedTable from './createBasinOilProdExportedTable';
import createCountryGasPricesTableInput from './createCountryGasPricesTableInput';
import createNorwayGasPricesTableInput from './createNorwayGasPricesTableInput';
import createNorwayFuturePriceTableInput from './createNorwayFuturePriceTableInput';
import initGhgInputPage from './initGhgInputPage';
import createTrinidadTaxRateTableInput from './createTrinidadTaxRateTableInput';
import createWindfallTaxTable from './createWindfallTaxTable';
import createOilPricesMarketTableInput from './createOilPricesMarketTableInput';
import initReportNotes from '../common/initReportNoteInputs';

export default (countryId, options = {}) => {
  try {
    countryId = parseInt(countryId);

    const referenceProperties = JSON.parse($('#reference-properties').val());

    switch (countryId) {
      case JSCONSTANT.argentina_id:
        createCorporateTaxRateTable(referenceProperties);
        createExportTaxesOilTable(referenceProperties);
        createExportTaxesGasTable(referenceProperties);
        createOilProductionExportedTable(referenceProperties);
        createGasProductionExportedTable(referenceProperties);
        createProvincialSalesTaxTable(referenceProperties);
        createGasPricesTable(referenceProperties);
        createOilPriceOverridesTable(referenceProperties);
        createBasinOilProdExportedTable(referenceProperties);
        initGhgInputPage();

        break;
      case JSCONSTANT.trinidad_id:
        createCountryGasPricesTableInput(countryId, referenceProperties);
        createGasPricesTable(referenceProperties);
        createOilPriceOverridesTable(referenceProperties);
        createBasinOilProdExportedTable(referenceProperties);
        createTrinidadTaxRateTableInput(referenceProperties);

        break;
      case JSCONSTANT.cuba_id:
        createCorporateTaxRateTable(referenceProperties);
        createExportTaxesOilTable(referenceProperties);
        createExportTaxesGasTable(referenceProperties);
        createOilProductionExportedTable(referenceProperties);
        createGasProductionExportedTable(referenceProperties);
        createProvincialSalesTaxTable(referenceProperties);
        createGasPricesTable(referenceProperties);
        createOilPriceOverridesTable(referenceProperties);
        break;
      case JSCONSTANT.ecuador_id:
        createCitTable(referenceProperties);
        break;
      case JSCONSTANT.mozambique_id:
      case JSCONSTANT.nigeria_id:
      case JSCONSTANT.ghana_id:
        createCountryGasPricesTableInput(countryId, referenceProperties);
        break;
      case JSCONSTANT.norway_id:
        createNorwayGasPricesTableInput(referenceProperties);
        createNorwayFuturePriceTableInput(referenceProperties);
        break;
      case JSCONSTANT.vietnam_id:
        createCorporateTaxRateTable(referenceProperties);
        break;
      case JSCONSTANT.india_id:
        createWindfallTaxTable(referenceProperties);
        break;
      case JSCONSTANT.alaska_id:
        createOilPricesMarketTableInput(referenceProperties);
        break;
    }

    initReportNotes();
  } catch (e) {
    console.error(e);
  }
};
