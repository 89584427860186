import Handsontable from 'handsontable';
import $ from 'jquery';
export default (countryId) => {
  let element = document.getElementById('asset-ghg-overrides-table');
  if (!element) return;

  const inputData = JSON.parse($('#asset-ghg-overrides-data').val());
  const facilityData = JSON.parse($('#facility-data').val());
  const settings = {
    data: inputData,
    colHeaders: ['Name', 'Value'],
    colWidths: [300, 150],
    cells(row, column) {
      const cellMeta = {};
      if (column == 0) {
        (cellMeta.type = 'text'), (cellMeta.editor = false);
      } else {
        if (row < 11) {
          if (countryId === JSCONSTANT.norway_id && row < 6) {
            cellMeta.editor = false;
          } else {
            cellMeta.type = 'numeric';
            cellMeta.numericFormat = {
              pattern: '0,0.[00000000]',
            };
          }
        } else if (row == 11) {
          cellMeta.type = 'autocomplete';
          cellMeta.source = facilityData;
        } else {
          cellMeta.type = 'numeric';
        }
      }

      return cellMeta;
    },

    afterChange: function (event) {
      let newData = this.getData()
        .map((subArray) => subArray.join(':'))
        .join(';');
      $(`#asset-ghg-overrides`).val(newData);
    },
  };

  let table = new Handsontable(element, settings);

  $('a[data-toggle="tab"][href="#ghg-asset-inputs"]').on(
    'shown.bs.tab',
    (event) => table.render(),
  );
};
