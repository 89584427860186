import Handsontable from 'handsontable';
import $ from 'jquery';
export default (country_id) => {
  let colHeaders, columns, colWidths;
  let element = document.getElementById('asset-processing-overrides-table');
  if (!element) return;

  switch (country_id) {
    case JSCONSTANT.colombia_id:
      (colWidths = [50, ...Array(3).fill(130)]),
        (colHeaders = ['Year', 'Water Cut( %)']);
      columns = [
        {
          type: 'numeric',
        },
        ...Array(1).fill({
          type: 'numeric',
          numericFormat: {
            pattern: '0,0.[00000000]',
          },
        }),
      ];
      break;
    default: {
      (colWidths = [50, ...Array(5).fill(130)]),
        (colHeaders = [
          'Year',
          'Water Cut( %)',
          'Water Injection(%)',
          'Gas Injection(%)',
        ]);
      columns = [
        {
          type: 'numeric',
        },
        ...Array(3).fill({
          type: 'numeric',
          numericFormat: {
            pattern: '0,0.[00000000]',
          },
        }),
      ];
    }
  }

  const inputData = JSON.parse($('#asset-processing-overrides-data').val());
  const settings = {
    data: inputData,
    colWidths: colWidths,
    colHeaders: colHeaders,
    columns: columns,

    afterChange: function (event) {
      let newData = this.getData()
        .map((subArray) => subArray.join(':'))
        .join(';');
      $(`#asset-processing-overrides`).val(newData);
    },
  };

  let table = new Handsontable(element, settings);

  $('a[data-toggle="tab"][href="#ghg-asset-inputs"]').on(
    'shown.bs.tab',
    (event) => table.render(),
  );
};
