import Handsontable from 'handsontable';
import $ from 'jquery';
import afterChangeEventHanlderForSingleInput from './afterChangeEventHanlderForSingleInput';

export default (country_id, referenceProperties) => {
  const element = document.getElementById('royalty-table');
  if (!element) return;

  const inputData = JSON.parse($('#royalty-input-data').val());

  let colHeaders, columns, colWidths;
  switch (country_id) {
    case JSCONSTANT.argentina_id:
      {
        colHeaders = ['Year', 'Conventional', 'Unconventional'];

        columns: [
          {
            editor: false,
          },
          ...Array(2).fill({
            type: 'numeric',
            numericFormat: {
              pattern: '0,0',
            },
          }),
        ];

        colWidths = [100, 150, 150];
      }
      break;
    case JSCONSTANT.brazil_id:
      {
        colHeaders = ['Year', 'Percent'];

        columns: [
          {
            editor: false,
          },
          {
            type: 'numeric',
            numericFormat: {
              pattern: '0,0',
            },
          },
        ];

        colWidths = [100, 100];
      }
      break;
    default: {
      {
        colHeaders = ['Year', 'Conventional', 'Unconventional'];

        columns: [
          {
            editor: false,
          },
          ...Array(2).fill({
            type: 'numeric',
            numericFormat: {
              pattern: '0,0',
            },
          }),
        ];

        colWidths = [100, 150, 150];
      }
    }
  }
  const settings = {
    data: inputData,
    colWidths,
    colHeaders,
    columns,

    afterChange: function (event) {
      const propertyId = referenceProperties.royalty;

      afterChangeEventHanlderForSingleInput(propertyId, this.getData(), ':');
    },
  };

  let table = new Handsontable(element, settings);

  window.handsontableInstances.push(table);

  $('a[data-toggle="tab"][href="#financial-considerations"]').on(
    'shown.bs.tab',
    (event) => table.render(),
  );
};
