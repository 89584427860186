import Handsontable from 'handsontable';
import $ from 'jquery';
import afterChangeEventHanlderForMultipleInput from './afterChangeEventHanlderForMultipleInput';

export default (referenceProperties) => {
  const element = document.getElementById('provincial-sales-tax-table');
  if (!element) return;

  const inputData = JSON.parse($('#provincial-sales-tax-input-data').val());
  const settings = {
    data: inputData,
    columns: [
      {
        data: 'year',
        type: 'text',
      },
      {
        data: 'chubutTax',
        type: 'numeric',
        numericFormat: {
          pattern: '0,0.[00000]',
        },
      },
      {
        data: 'federalTax',
        type: 'numeric',
        numericFormat: {
          pattern: '0,0.[00000]',
        },
      },
      {
        data: 'formosaTax',
        type: 'numeric',
        numericFormat: {
          pattern: '0,0.[00000]',
        },
      },
      {
        data: 'jujuyTax',
        type: 'numeric',
        numericFormat: {
          pattern: '0,0.[00000]',
        },
      },
      {
        data: 'laPampaTax',
        type: 'numeric',
        numericFormat: {
          pattern: '0,0.[00000]',
        },
      },
      {
        data: 'mendozaTax',
        type: 'numeric',
        numericFormat: {
          pattern: '0,0.[00000]',
        },
      },
      {
        data: 'neuquenTax',
        type: 'numeric',
        numericFormat: {
          pattern: '0,0.[00000]',
        },
      },
      {
        data: 'rioNegroTax',
        type: 'numeric',
        numericFormat: {
          pattern: '0,0.[00000]',
        },
      },
      {
        data: 'saltaTax',
        type: 'numeric',
        numericFormat: {
          pattern: '0,0.[00000]',
        },
      },
      {
        data: 'santaCruzTax',
        type: 'numeric',
        numericFormat: {
          pattern: '0,0.[00000]',
        },
      },
      {
        data: 'tierradelFuegoTax',
        type: 'numeric',
        numericFormat: {
          pattern: '0,0.[00000]',
        },
      },
    ],
    rowHeaders: false,
    colWidths: [70, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 130],
    colHeaders: [
      'Year',
      'Chubut',
      'Federal',
      'Formosa',
      'Jujuy',
      'La Pampa',
      'Mendoza',
      'Neuquen',
      'Rio Negro',
      'Salta',
      'Santa Cruz',
      'Tierra del Fuego',
    ],

    afterChange: function (event) {
      const temp_result = {
        chubutTax: [],
        federalTax: [],
        formosaTax: [],
        jujuyTax: [],
        laPampaTax: [],
        mendozaTax: [],
        neuquenTax: [],
        rioNegroTax: [],
        saltaTax: [],
        santaCruzTax: [],
        tierraDelFuegoTax: [],
      };
      afterChangeEventHanlderForMultipleInput(
        temp_result,
        this.getSourceData(),
        referenceProperties,
      );
    },
  };

  let table = new Handsontable(element, settings);
  window.handsontableInstances.push(table);

  $('a[data-toggle="tab"][href="#taxes"]').on('shown.bs.tab', (event) =>
    table.render(),
  );
};
