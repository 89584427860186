import Handsontable from 'handsontable';
import $ from 'jquery';
import afterChangeEventHanlderForSingleInput from './afterChangeEventHanlderForSingleInput';

export default (referenceProperties) => {
  const element = document.getElementById('field-production-table');
  if (!element) return;

  const inputData = JSON.parse($('#field-production-input-data').val());
  const settings = {
    data: inputData,
    rowHeaders: false,
    colWidths: [45, 65, 65, 65],
    afterChange: function (event) {
      const propertyId = referenceProperties.fieldProduction;
      afterChangeEventHanlderForSingleInput(propertyId, this.getData(), ',');
    },
  };

  let table = new Handsontable(element, settings);
  window.handsontableInstances.push(table);

  $('a[data-toggle="tab"][href="#development-schedule"]').on(
    'shown.bs.tab',
    (event) => table.render(),
  );
};
