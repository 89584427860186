import Handsontable from 'handsontable';
import $ from 'jquery';
import afterChangeEventHanlderForSingleInput from './afterChangeEventHanlderForSingleInput';

export default (referenceProperties) => {
  const element = document.getElementById('hist-wells-table');
  if (!element) return;

  let colHeaders, columns;

  colHeaders = [
    'Year',
    'Development Well (Capex)',
    'Injection Well (Capex)',
    'Successful Exploration Well (Capex)',
    'Unsuccessful Exploration Well (Opex)',
    'Appraisal Well (Capex)',
  ];
  columns = [
    {
      editor: false,
    },
    {
      type: 'numeric',
    },
    {
      type: 'numeric',
    },
    {
      type: 'numeric',
    },
    {
      type: 'numeric',
    },
    {
      type: 'numeric',
    },
  ];

  const inputData = JSON.parse($('#hist-wells-input-data').val());
  const settings = {
    data: inputData,
    columns,
    colHeaders,
    afterChange: function (event) {
      const propertyId = referenceProperties.histWells;
      afterChangeEventHanlderForSingleInput(propertyId, this.getData(), ':');
    },
  };

  let table = new Handsontable(element, settings);

  window.handsontableInstances.push(table);
  $('a[data-toggle="tab"][href="#development-schedule"]').on(
    'shown.bs.tab',
    (event) => table.render(),
  );
};
