import $ from 'jquery';
export default (temp_result, sourceData, referenceProperties) => {
  let property;

  for (let data_row of sourceData) {
    for (property of Object.keys(temp_result)) {
      const value = parseFloat(data_row[property]) || 0;
      temp_result[property].push(`${data_row.year}:${value}`);
    }
  }

  const result = Object.keys(temp_result).reduce(function (newObject, key) {
    newObject[key] = temp_result[key].join(';');
    return newObject;
  }, {});

  return (() => {
    const result1 = [];
    for (property in referenceProperties) {
      const id = referenceProperties[property];
      if (result[property]) {
        result1.push($(`#property_${id}`).val(result[property]));
      } else {
        result1.push(undefined);
      }
    }
    return result1;
  })();
};
