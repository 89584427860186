import $ from 'jquery';
export default (propertyId, updatedData) => {
  updatedData.shift();
  const newValue = updatedData
    .map(function (row) {
      row.shift();
      return row.join(':');
    })
    .join(';');

  $(`#property_${propertyId}`).val(newValue);
};
