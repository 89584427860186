import Handsontable from 'handsontable';
import $ from 'jquery';
import afterChangeEventHanlderForSingleInput from './afterChangeEventHanlderForSingleInput';

export default (referenceProperties) => {
  const element = document.getElementById('market-ratio-overrides-table');
  const inputData = JSON.parse($('#matket-ratio-overrides-input-data').val());

  const settings = {
    data: inputData,
    colWidths: [150, 150],
    colHeaders: ['Market Name', '%'],
    columns: [
      {
        editor: false,
      },
      {
        type: 'numeric',
        numericFormat: {
          pattern: '0,0',
        },
      },
    ],

    afterChange: function (event) {
      const propertyId = referenceProperties.gasMarketRatioOverrides;

      afterChangeEventHanlderForSingleInput(propertyId, this.getData(), ':');
    },
  };

  let table = new Handsontable(element, settings);

  window.handsontableInstances.push(table);

  $('a[data-toggle="tab"][href="#prices"]').on('shown.bs.tab', (event) =>
    table.render(),
  );
};
