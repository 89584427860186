import Handsontable from 'handsontable';
import $ from 'jquery';
import afterChangeEvent from './afterChangeEvent';

export default () => {
  let element = document.getElementById('brent-and-henry-hub-price');
  if (!element) return;

  let inputElement = $('#brent-and-henry-hub-price-input-data');
  let propertyId = inputElement.data()['id'];
  let dataSource = JSON.parse(inputElement.val());

  new Handsontable(element, {
    data: dataSource,
    rowHeaders: false,
    afterChange: function () {
      let updatedData = this.getData();
      afterChangeEvent(propertyId, updatedData, ',');
    },
  });
};
