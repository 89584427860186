import Handsontable from 'handsontable';
import $ from 'jquery';
import afterChangeEventHanlderForSingleInput from './afterChangeEventHanlderForSingleInput';

export default (referenceProperties) => {
  const element = document.getElementById('facility-information-table');
  if (!element) return;

  const inputData = JSON.parse($('#facility-information-input-data').val());

  const settings = {
    data: inputData,
    rowHeaders: false,
    colWidths: [80, 100, 100, 100, 100, 100, 100, 100, 100],
    cells(row, column) {
      const cellMeta = {};
      if (row === 0 && column > 0 && column < 9) {
        cellMeta.type = 'dropdown';
        cellMeta.source = ['1Purchased', 'leased', 'Logistics', 'O&M'];
      }
      return cellMeta;
    },
    afterChange: function (event) {
      const propertyId = referenceProperties.facilityInformation;
      afterChangeEventHanlderForSingleInput(propertyId, this.getData(), ',');
    },
  };

  let table = new Handsontable(element, settings);
  window.handsontableInstances.push(table);

  $('a[data-toggle="tab"][href="#development-schedule"]').on(
    'shown.bs.tab',
    (event) => table.render(),
  );
};
