import Handsontable from 'handsontable';
import $ from 'jquery';
import afterChangeEventHanlderForSingleInput from './afterChangeEventHanlderForSingleInput';

export default (countryId, referenceProperties) => {
  let countries = JSCONSTANT.render_development_phase_table;

  if (!countries.includes(countryId)) {
    return;
  }

  const element = document.getElementById('development-phases-table');
  if (!element) return;
  const inputElement = JSON.parse($('#development-phases-input-data').val());

  let settings = {
    data: inputElement,
    colHeaders: [
      'Phase Details: Name, Reserves, FPSO Size',
      'Turn Phases On or Off (1 = On, 0 = Off)',
      'Start Date (MM/DD/AAAA)',
      'Number of Years',
    ],
    columns: [
      {},
      {
        editor: 'select',
        selectOptions: ['1', '0'],
      },
      {},
      {},
    ],
    rowHeaders: false,
    colWidths: [550, 300, 200, 170],
    afterChange: function () {
      const propertyId = referenceProperties.developmentPhases;
      afterChangeEventHanlderForSingleInput(propertyId, this.getData(), ':');

      const newValue = this.getData()
        .map((subArray) => subArray.join(':'))
        .join(';');

      $('#development-phases-input-data').val(newValue);
    },
  };

  if (countryId == JSCONSTANT.guyana_id) {
    settings = {
      ...settings,
      colHeaders: [
        'Phase Details: Name, Reserves, FPSO Size',
        'Turn Phases On or Off (1 = On, 0 = Off)',
        'Start Date (MM/DD/AAAA)',
        'Number of Years',
        'Type Curve',
        'GOR (scf/bbl)',
      ],
      columns: [
        {},
        {
          editor: 'select',
          selectOptions: ['1', '0'],
        },
        {},
        {},
        {
          editor: 'select',
          selectOptions: ['Oil 1', 'Oil 2', 'Oil 3'],
        },
        {},
      ],
      rowHeaders: false,
      colWidths: [550, 300, 200, 120, 120, 120],
      maxRows: 10,
    };
  }

  let table = new Handsontable(element, settings);
  window.handsontableInstances.push(table);

  $('a[data-toggle="tab"][href="#development-schedule"]').on(
    'shown.bs.tab',
    (event) => table.render(),
  );
};
