import $ from 'jquery';

/**
 * Synchronizes the value of a property input field with the corresponding
 * title or description value.
 *
 * @param {string} propId - The ID of the property.
 * @param {string} name - The name of the property field ('title' or
 *  'description').
 * @param {string} value - The new value to be synchronized.
 */
export const syncToInput = (propId, name, value) => {
  let currentValue;
  try {
    currentValue = JSON.parse($(`[name='property[${propId}]']`).val());
  } catch (err) {
    currentValue = {
      title: '',
      description: $(`[name='property[${propId}]']`).val() || '',
    };
  }
  currentValue[name] = value;
  $(`[name='property[${propId}]']`).val(JSON.stringify(currentValue));
  $(`[name='property_description[${propId}]']`).text(value);
};

/**
 * Initializes the report note inputs in the admin interface.
 *
 * This function sets up event listeners for changes to the CKEDITOR instances
 * and the property title inputs. When a change occurs, it synchronizes the
 * values to the corresponding hidden input fields.
 */
const initReportNotes = () => {
  Object.entries(CKEDITOR.instances).forEach(([_, editor]) => {
    editor.on('change', (_event) => {
      const [propId] = editor.name.split('_').slice(-1);
      const value = editor.getData();

      syncToInput(propId, 'description', value);
    });
  });

  $(document).on('change', "[name^='property_title']", (event) => {
    const [propId] = event.target.id.split('_').slice(-1);
    const value = event.target.value;

    syncToInput(propId, 'title', value);
  });
};

export default initReportNotes;
