import Handsontable from 'handsontable';
import $ from 'jquery';
export default () => {
  const gasSplitElement = document.getElementById('operator-gas-split-table');
  if (!gasSplitElement) return;

  const settings = () => ({
    columns: [
      {
        editor: false,
        type: 'text',
      },
      {
        type: 'numeric',
        numericFormat: {
          pattern: '0,0.[00]',
        },
      },
      {
        type: 'numeric',
        numericFormat: {
          pattern: '0,0.[00]',
        },
      },
    ],
    rowHeaders: false,
    colWidths: [50, 150, 150],
    stretchH: 'all',
    colHeaders: ['Year', 'Flaring Share (%)', 'Venting Share (%)'],
  });

  const gasSplitTable = new Handsontable(
    gasSplitElement,
    settings('gas_price'),
  );
  window.handsontableInstances.push(gasSplitTable);

  $('a[data-toggle="tab"][href="#ghg-inputs"]').on('shown.bs.tab', (event) =>
    gasSplitTable.render(),
  );
};
