import Handsontable from 'handsontable';
import $ from 'jquery';
const afterChangeEventHanlderForSingleInput = (
  propertyId,
  updatedData,
  separator,
) => {
  const newValue = updatedData
    .filter((row) => row[0] !== null)
    .map((subArray) => subArray.join(separator))
    .join(';');

  $(`#property_${propertyId}`).val(newValue);
};

export default (country_id, referenceProperties) => {
  const element = document.getElementById('liquids-production-table');
  if (!element) return;

  const inputData = JSON.parse($('#liquids-production-input-data').val());
  let colHeaders, columns, colWidths;

  switch (country_id) {
    case JSCONSTANT.cuba_id:
      colWidths = [100, 150];
      colHeaders = ['Year', 'Liquids'];
      columns = [
        {
          editor: false,
        },
        {
          type: 'numeric',
          numericFormat: {
            pattern: '0,0.[00]',
          },
        },
      ];
      break;
    case JSCONSTANT.saudi_arabia_id:
    case JSCONSTANT.egypt_id:
      colWidths = [50, 120, 130, 120, 130];
      colHeaders = [
        'Year',
        'Oil PDP',
        'Condensate PDP',
        'Oil UD',
        'Condensate UD',
      ];
      columns = [
        {
          editor: false,
        },
        ...Array(4).fill({
          type: 'numeric',
          numericFormat: {
            pattern: '0,0.[00]',
          },
        }),
      ];
      break;
    default: {
      // For Trinidad, Mozamique, Nigeria, Ghana, Israel, Cyprus
      colWidths = [100, 150, 150];
      colHeaders = ['Year', 'Oil', 'Condensate'];
      columns = [
        {
          editor: false,
        },
        ...Array(2).fill({
          type: 'numeric',
          numericFormat: {
            pattern: '0,0.[00]',
          },
        }),
      ];
    }
  }

  const settings = {
    data: inputData,
    colWidths: colWidths,
    colHeaders: colHeaders,
    columns: columns,

    afterChange: function (event) {
      const propertyId = referenceProperties.liquidsProduction;

      afterChangeEventHanlderForSingleInput(propertyId, this.getData(), ':');
    },
  };

  let table = new Handsontable(element, settings);

  window.handsontableInstances.push(table);

  $('a[data-toggle="tab"][href="#production"]').on('shown.bs.tab', (event) =>
    table.render(),
  );
};
