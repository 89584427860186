import Handsontable from 'handsontable';
import $ from 'jquery';
import afterChangeEventHanlderForSingleInput from './afterChangeEventHanlderForSingleInput';

export default (referenceProperties) => {
  const element = document.getElementById('feed-gas-production-table');
  if (!element) return;

  const inputData = JSON.parse($('#feed-gas-production-input-data').val());

  const settings = {
    data: inputData,
    rowHeaders: true,
    colHeaders: false,
    colWidths: [150, 150, 150, 150],
    mergeCells: [
      { row: 0, col: 0, rowspan: 1, colspan: 4 },
      { row: 2, col: 0, rowspan: 1, colspan: 4 },
    ],
    cells(row, column) {
      const cellProperties = {};
      if (row === 0 || row === 2) {
        cellProperties.readOnly = true;
      }
      return cellProperties;
    },
    className: 'htCenter',
    afterChange: function (event) {
      const propertyId = referenceProperties.feedGasProduction;

      afterChangeEventHanlderForSingleInput(propertyId, this.getData(), ',');
    },
  };

  let table = new Handsontable(element, settings);
  window.handsontableInstances.push(table);

  $('a[data-toggle="tab"][href="#financial-considerations"]').on(
    'shown.bs.tab',
    (event) => table.render(),
  );
};
