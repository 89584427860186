import Handsontable from 'handsontable';
import $ from 'jquery';
export default () => {
  let element = document.getElementById('hist-fuel-overrides-table');
  if (!element) return;

  const inputData = JSON.parse($('#hist-fuel-overrides-data').val());
  const settings = {
    data: inputData,
    colWidths: [70, ...Array(6).fill(160)],
    colHeaders: [
      'Year',
      'Gas Low (MMcf/d)',
      'Gas Base (MMcf/d)',
      'Gas High (MMcf/d)',
      'Diesel Low (gal/d)',
      'Diesel Base (gal/d)',
      'Diesel High (gal/d)',
    ],
    columns: [
      { type: 'numeric' },
      ...Array(6).fill({
        type: 'numeric',
        numericFormat: {
          pattern: '0,0.[00000000]',
        },
      }),
    ],

    afterChange: function (event) {
      let newData = this.getData()
        .map((subArray) => subArray.join(':'))
        .join(';');
      $(`#hist-fuel-overrides`).val(newData);
    },
  };

  let table = new Handsontable(element, settings);

  $('a[data-toggle="tab"][href="#ghg-asset-inputs"]').on(
    'shown.bs.tab',
    (event) => table.render(),
  );
};
