import $ from 'jquery';
import hideAlert from '../common/hideAlert';

export default function (_) {
  hideAlert($('.alert').not('.admin'));
  let importForm = $(this);
  let buttonImportSelector =
    ".save-button.filter-button[data-action='import-country-fields']";
  let buttonImport = importForm.find(buttonImportSelector);

  if (importForm.find('#import_csv').val().length) {
    buttonImport.addClass('disabled');

    return true;
  } else {
    let labelError = $('label.import-error');
    labelError.text(I18n.t('admin.countries.index.csv_file_required'));
    labelError.show();

    return false;
  }
}
