import $ from 'jquery';
import hideAlert from './hideAlert';
import showAlert from './showAlert';
import showAssetStaticPropeties from './showAssetStaticProperties';

export default (ev) => {
  let btn = $(ev.target).parent();

  if ($(btn).hasClass('disabled')) {
    return;
  }

  $(btn).addClass('disabled');

  let params = {
    country_id: $(btn).data('countryId'),
    asset_id: $(btn).data('assetId'),
    asset_type: $(btn).data('assetType'),
  };

  $.ajax({
    url: $(btn).data('url'),
    data: params,
    method: 'POST',
    success: (_response) => {
      showAlert('Sync backend data success', 'info');
      setTimeout(hideAlert, 3000, '.admin.alert');
      showAssetStaticPropeties(
        params['country_id'],
        params['asset_type'].toLowerCase(),
        params['asset_id'],
      );
    },
    error: (response) => {
      showAlert(response.responseJSON.error, 'danger');
    },
    complete: (_response) => {
      $(btn).removeClass('disabled');
    },
  });
};
