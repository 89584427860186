import Handsontable from 'handsontable';
import $ from 'jquery';
export default () => {
  let element = document.getElementById('asset-ghg-flaring-inputs-table');
  if (!element) return;

  const inputData = JSON.parse($('#asset-ghg-flaring-inputs-data').val());
  const settings = {
    data: inputData,
    colHeaders: ['Emissions Source', 'Start Year', 'Pct Decrease'],
    colWidths: [150, 70, 150],
    columns: [
      {
        type: 'autocomplete',
        source: ['Flaring', 'Venting'],
      },
      {
        type: 'numeric',
        numericFormat: {
          pattern: '0',
        },
      },
      {
        type: 'numeric',
        numericFormat: {
          pattern: '0',
        },
      },
    ],

    afterChange: function (event) {
      let newData = this.getData()
        .map((subArray) => subArray.join(':'))
        .join(';');
      $(`#asset-ghg-flaring-inputs`).val(newData);
    },
  };

  let table = new Handsontable(element, settings);

  $('a[data-toggle="tab"][href="#ghg-asset-inputs"]').on(
    'shown.bs.tab',
    (event) => table.render(),
  );
};
