import $ from 'jquery';
import '@selectize/selectize';

export default (country_id) => {
  try {
    const gasSplitDropdown = $('#ghg-inputs .selectize').selectize({
      placeholder: I18n.t('js.customer.select'),
      onChange: async (value) => {
        const gasSplitTable = window.handsontableInstances.find(
          (i) => i.rootElement.id === 'operator-gas-split-table',
        );

        let url = [
          '/admin/countries',
          country_id,
          'operator_gas_splits',
          value,
        ].join('/');

        const { data } = await $.get({ url });

        gasSplitTable.loadData(data);

        $('#gas-split-save-btn').removeClass('disabled');
      },
    });

    let flashMessageTimeout;

    $('#gas-split-save-btn').click(() => {
      const companyId = gasSplitDropdown[0].selectize.getValue();
      if (!companyId) return;

      const gasSplitTable = window.handsontableInstances.find(
        (i) => i.rootElement.id === 'operator-gas-split-table',
      );

      const tableData = gasSplitTable.getData();

      let url = [
        '/admin/countries',
        country_id,
        'operator_gas_splits',
        companyId,
      ].join('/');
      const message = $('.admin .message');

      $.ajax({
        method: 'PUT',
        url,
        data: { table_data: JSON.stringify(tableData) },
        success: () => {
          message.slideDown();
          clearTimeout(flashMessageTimeout);
          flashMessageTimeout = setTimeout(() => message.slideUp(), 2000);
        },
      });
    });
  } catch (error) {
    console.log(error);
  }
};
