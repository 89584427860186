import Handsontable from 'handsontable';
import $ from 'jquery';
import afterChangeEventHanlderForSingleInput from './afterChangeEventHanlderForSingleInput';

export default (referenceProperties) => {
  const levyTaxElement = document.getElementById(
    'unemployment-levy-tax-rate-table',
  );
  const incomeTaxElement = document.getElementById(
    'corporate-income-tax-rate-table',
  );

  if (!levyTaxElement || !incomeTaxElement) return;

  const inputData = JSON.parse($('#trinidad_tax_rate_input_data').val());

  const settings = (propName) => ({
    data: inputData[propName],
    columns: [
      {
        editor: false,
        type: 'text',
      },
      {
        type: 'numeric',
        numericFormat: {
          pattern: '0,0.[00]',
        },
      },
    ],
    rowHeaders: false,
    colWidths: [120, 100],
    colHeaders: ['Year', 'Rate (%)'],

    afterChange: function (event) {
      let propertyId;
      switch (propName) {
        case 'unemployment_levy_tax_rate':
          propertyId = referenceProperties.unemploymentLevyTaxRate;
          break;
        case 'corporate_income_tax_rate':
          propertyId = referenceProperties.corporateIncomeTaxRate;
          break;
      }

      afterChangeEventHanlderForSingleInput(propertyId, this.getData(), ':');
    },
  });

  const levyTaxTable = new Handsontable(
    levyTaxElement,
    settings('unemployment_levy_tax_rate'),
  );
  const incomeTaxTable = new Handsontable(
    incomeTaxElement,
    settings('corporate_income_tax_rate'),
  );
  window.handsontableInstances.push(levyTaxTable);
  window.handsontableInstances.push(incomeTaxTable);

  $('a[data-toggle="tab"][href="#taxes"]').on('shown.bs.tab', (event) =>
    levyTaxTable.render(),
  );
  $('a[data-toggle="tab"][href="#taxes"]').on('shown.bs.tab', (event) =>
    incomeTaxTable.render(),
  );
};
