import Handsontable from 'handsontable';
import $ from 'jquery';
import afterChangeEventHanlderForSingleInput from './afterChangeEventHanlderForSingleInput';

export default (country, referenceProperties) => {
  const element = document.getElementById('historical-capex-table');
  if (!element) return;

  const inputData = JSON.parse($('#historical-capex-input-data').val());
  const columns = [
    {
      type: 'text',
    },
    {
      type: 'numeric',
      numericFormat: {
        pattern: '0,0.[00]',
      },
    },
  ];
  const settings = {
    data: inputData,
    columns,
    rowHeaders: false,
    colHeaders: ['Year', 'Average Well Costs'],

    afterChange: function (event) {
      const propertyId = referenceProperties.averageWellCosts;

      afterChangeEventHanlderForSingleInput(propertyId, this.getData(), ':');
    },
  };

  let table = new Handsontable(element, settings);

  window.handsontableInstances.push(table);
  $('a[data-toggle="tab"][href="#base-cost-inputs-in-nominal-terms"]').on(
    'shown.bs.tab',
    (event) => table.render(),
  );
};
