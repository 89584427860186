import Handsontable from 'handsontable';
import $ from 'jquery';
import afterChangeEventHanlderForSingleInput from './afterChangeEventHanlderForSingleInput';

export default (referenceProperties) => {
  const element = document.getElementById(
    'historical-average-well-costs-table',
  );
  if (!element) return;

  const inputData = JSON.parse($('#historical-average-well-costs-data').val());

  const settings = {
    data: inputData,
    colWidths: [150, 150],
    colHeaders: ['Year', 'Well Cost (US$MM)'],
    columns: [
      {
        editor: false,
      },
      {
        type: 'numeric',
        numericFormat: {
          pattern: '0,0.[00]',
        },
      },
    ],

    afterChange: function (event) {
      const propertyId = referenceProperties.historicalAverageWellCosts;

      afterChangeEventHanlderForSingleInput(propertyId, this.getData(), ':');
    },
  };

  let table = new Handsontable(element, settings);
  window.handsontableInstances.push(table);

  $('a[data-toggle="tab"][href="#base-cost-inputs-in-nominal-terms"]').on(
    'shown.bs.tab',
    (event) => table.render(),
  );
};
