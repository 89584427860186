import Handsontable from 'handsontable';
import $ from 'jquery';
import afterChangeEventHanlderForSingleInput from './afterChangeEventHanlderForSingleInput';

export default (referenceProperties) => {
  const element = document.getElementById('gas-monetization-vertical-table');
  const inputData = JSON.parse(
    $('#gas-monetization-vertical-input-data').val(),
  );

  const settings = {
    data: inputData,
    colWidths: [250, 100],
    columns: [
      {
        editor: false,
      },
      {
        type: 'numeric',
        numericFormat: {
          pattern: '0.[00000]',
        },
      },
    ],

    afterChange: function (_e) {
      const propertyId = referenceProperties.gasMonetization;

      afterChangeEventHanlderForSingleInput(propertyId, this.getData(), ':');
    },
  };

  let table = new Handsontable(element, settings);

  window.handsontableInstances.push(table);

  $('a[data-toggle="tab"][href="#financial-considerations"]').on(
    'shown.bs.tab',
    (_e) => table.render(),
  );
};
