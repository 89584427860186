import Handsontable from 'handsontable';
import $ from 'jquery';
import afterChangeEventHanlderForSingleInput from './afterChangeEventHanlderForSingleInput';

export default (country_id, referenceProperties) => {
  const element = document.getElementById('liquids-pricing-market-table');
  if (!element) return;

  const inputData = JSON.parse($('#liquids-pricing-market-data').val());

  let header =
    country_id === JSCONSTANT.kazakhstan_id
      ? [
          'Year',
          'KEBCO (Urals)',
          'CPC',
          'China Exports',
          'BTC (via Aktau)',
          'Other',
          'Domestic',
        ]
      : [
          'Year',
          'ESPO',
          'Black Sea',
          'China (via Kazakhstan)',
          'Baltic (Primorsk)',
          'Other',
          'Domestic',
        ]; // Russia country

  const settings = {
    data: inputData,
    colWidths: [50, 150, 100, 150, 150, 100, 100],
    colHeaders: header,
    columns: [
      {
        editor: false,
      },
      {
        type: 'numeric',
        numericFormat: {
          pattern: '0,0.[00]',
        },
      },
      {
        type: 'numeric',
        numericFormat: {
          pattern: '0,0.[00]',
        },
      },
      {
        type: 'numeric',
        numericFormat: {
          pattern: '0,0.[00]',
        },
      },
      {
        type: 'numeric',
        numericFormat: {
          pattern: '0,0.[00]',
        },
      },
      {
        type: 'numeric',
        numericFormat: {
          pattern: '0,0.[00]',
        },
      },
      {
        type: 'numeric',
        numericFormat: {
          pattern: '0,0.[00]',
        },
      },
    ],

    afterChange: function (event) {
      const propertyId = referenceProperties.liquidsPricingMarket;

      afterChangeEventHanlderForSingleInput(propertyId, this.getData(), ':');
    },
  };

  let table = new Handsontable(element, settings);

  window.handsontableInstances.push(table);

  $('a[data-toggle="tab"][href="#financial-considerations"]').on(
    'shown.bs.tab',
    (event) => table.render(),
  );
};
