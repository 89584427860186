import Handsontable from 'handsontable';
import $ from 'jquery';
import afterChangeEventHanlderForMultipleInput from './afterChangeEventHanlderForMultipleInput';

export default (referenceProperties) => {
  const element = document.getElementById('oil-price-overrides-table');
  if (!element) return;

  const inputData = JSON.parse($('#oil-price-overrides-input-data').val());
  const settings = {
    data: inputData,
    columns: [
      {
        data: 'year',
        type: 'text',
      },
      {
        data: 'oilPriceOverrides',
        type: 'autocomplete',
        numericFormat: {
          pattern: '0,0.[00]',
        },
      },
    ],
    rowHeaders: false,
    colWidths: [200, 200],
    colHeaders: ['', 'Oil (US$/bbl)'],

    afterChange: function (event) {
      const temp_result = {
        oilPriceOverrides: [],
      };
      afterChangeEventHanlderForMultipleInput(
        temp_result,
        this.getSourceData(),
        referenceProperties,
      );
    },
  };

  let table = new Handsontable(element, settings);
  window.handsontableInstances.push(table);

  $('a[data-toggle="tab"][href="#oil-price-overrides"]').on(
    'shown.bs.tab',
    (event) => table.render(),
  );
};
