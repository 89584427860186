import $ from 'jquery';
import hideAlert from '../common/hideAlert';
import showAlert from '../common/showAlert';

function disableImportCompaniesButton(importCompanies) {
  let importCompaniesText = I18n.t('common.calculations_in_processing');
  importCompanies.addClass('disabled');
  importCompanies.text(importCompaniesText);
}

export default (ev) => {
  let btn = $(ev.target).parent();

  if ($(btn).hasClass('disabled')) {
    return;
  }

  $(btn).addClass('disabled');
  let runCalculationsFromCountry =
    btn.data('from') && btn.data('from') === 'country';

  $.ajax({
    url: $(btn).data('url'),
    method: 'POST',
    success: function (response) {
      showAlert('Recalculation and reporting started', 'info');
      setTimeout(hideAlert, 3000, '.admin.alert');
      if (runCalculationsFromCountry)
        disableRunCalulationsButton(
          $('.save-button[data-type="import-companies"]'),
        );
    },
    error: function (response) {
      showAlert(response.responseJSON.message, 'danger');
    },
    complete: function (response) {
      $('#confirm-calculation-modal').modal('hide');
      $(btn).removeClass('disabled');
    },
  });
};
