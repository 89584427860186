import Handsontable from 'handsontable';
import $ from 'jquery';
import afterChangeEventHanlderForSingleInput from './afterChangeEventHanlderForSingleInput';

export default (referenceProperties) => {
  const element = document.getElementById('lng-spot-price-table');
  if (!element) return;

  const inputData = JSON.parse($('#lng-spot-price-data').val());
  const marketValues = ['N Asia', 'SE Asia', 'Middle East', 'Europe', 'LatAm'];

  const settings = {
    data: inputData,
    colWidths: [150, 100],
    cells(row, column) {
      const cellMeta = {};
      if (column === 1) {
        cellMeta.type = 'dropdown';
        cellMeta.source = marketValues;
      }
      return cellMeta;
    },

    afterChange: function (event) {
      const propertyId = referenceProperties.lNGSpotPrice;

      afterChangeEventHanlderForSingleInput(propertyId, this.getData(), ':');
    },
  };

  let table = new Handsontable(element, settings);

  window.handsontableInstances.push(table);

  $('a[data-toggle="tab"][href="#financial-considerations"]').on(
    'shown.bs.tab',
    (event) => table.render(),
  );
};
