import Handsontable from 'handsontable';
import $ from 'jquery';
import afterChangeEventHanlderForSingleInput from './afterChangeEventHanlderForSingleInput';

export default (country_id, referenceProperties) => {
  let settings;
  const element = document.getElementById('well-schedule-table');
  if (!element) return;

  const inputData = JSON.parse($('#well-schedule-input-data').val());
  if (JSCONSTANT.render_well_schedule_with_10_phases.includes(country_id)) {
    settings = {
      data: inputData,
      colHeaders: [
        'Year',
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'G',
        'H',
        'I',
        'J',
        'K',
        'L',
        'M',
        'N',
        'O',
        'P',
        'Q',
        'R',
        'S',
        'T',
      ],
      columns: [
        {
          editor: false,
        },
        ...Array(20).fill({
          type: 'text',
          placeholder: 'NA',
        }),
      ],
      maxRows: JSCONSTANT.handsontable.wellScheduleRows,
      afterChange: function (event) {
        const propertyId = referenceProperties.wellSchedule;

        afterChangeEventHanlderForSingleInput(propertyId, this.getData(), ':');
      },
    };
  } else if (
    JSCONSTANT.render_well_schedule_with_5_phases.includes(country_id) &&
    country_id != JSCONSTANT.cameroon_id &&
    country_id != JSCONSTANT.alaska_id
  ) {
    settings = {
      data: inputData,
      colHeaders: ['Year', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'],
      columns: [
        {
          editor: false,
        },
        ...Array(10).fill({
          type: 'text',
          placeholder: 'NA',
        }),
      ],
      maxRows: JSCONSTANT.handsontable.wellScheduleRows,
      afterChange: function (event) {
        const propertyId = referenceProperties.wellSchedule;

        afterChangeEventHanlderForSingleInput(propertyId, this.getData(), ':');
      },
    };
  } else if (JSCONSTANT.well_schedule_1_case_country.includes(country_id)) {
    settings = {
      data: inputData,
      colHeaders: ['Year', 'Base Case'],
      columns: [
        {
          editor: false,
        },
        {
          type: 'text',
          placeholder: 'NA',
        },
      ],
      maxRows: JSCONSTANT.handsontable.wellScheduleRows,
      afterChange: function (event) {
        const propertyId = referenceProperties.wellSchedule;

        afterChangeEventHanlderForSingleInput(propertyId, this.getData(), ':');
      },
    };
  } else if (JSCONSTANT.well_schedule_2_case_country.includes(country_id)) {
    let headers =
      country_id == JSCONSTANT.alaska_id
        ? ['Year', 'Producer', 'Sidetracks']
        : ['Year', 'Producer', 'Injector'];

    settings = {
      data: inputData,
      colHeaders: headers,
      columns: [
        {
          editor: false,
        },
        ...Array(2).fill({
          type: 'text',
          placeholder: 'NA',
        }),
      ],
      maxRows: JSCONSTANT.handsontable.wellScheduleRows,
      afterChange: function (event) {
        const propertyId = referenceProperties.wellSchedule;

        afterChangeEventHanlderForSingleInput(propertyId, this.getData(), ':');
      },
    };
  } else if (JSCONSTANT.well_schedule_3_case_country.includes(country_id)) {
    let headers =
      country_id == JSCONSTANT.cyprus_id
        ? ['Year', 'Base Reserves', 'Low Reserves', 'High Reserves']
        : ['Year', '1P', '2P', '3P'];

    settings = {
      data: inputData,
      colHeaders: headers,
      columns: [
        {
          editor: false,
        },
        ...Array(3).fill({
          type: 'text',
          placeholder: 'NA',
        }),
      ],
      maxRows: JSCONSTANT.handsontable.wellScheduleRows,
      afterChange: function (event) {
        const propertyId = referenceProperties.wellSchedule;

        afterChangeEventHanlderForSingleInput(propertyId, this.getData(), ':');
      },
    };
  } else if (JSCONSTANT.well_schedule_5_case_country.includes(country_id)) {
    settings = {
      data: inputData,
      colHeaders: [
        'Year',
        'Conventional Oil',
        'Conventional Gas',
        'Tight Oil',
        'Tight Gas',
        'Shale Gas',
      ],
      columns: [
        {
          editor: false,
        },
        ...Array(5).fill({
          type: 'text',
          placeholder: 'NA',
        }),
      ],
      maxRows: JSCONSTANT.handsontable.wellScheduleRows,
      afterChange: function (event) {
        const propertyId = referenceProperties.wellSchedule;

        afterChangeEventHanlderForSingleInput(propertyId, this.getData(), ':');
      },
    };
  } else {
    settings = {
      data: inputData,
      columns: [
        {
          editor: false,
        },
        {
          type: 'text',
          placeholder: 'NA',
        },
      ],
      maxRows: JSCONSTANT.handsontable.wellScheduleRows,
      afterChange: function (event) {
        const propertyId = referenceProperties.wellSchedule;

        afterChangeEventHanlderForSingleInput(propertyId, this.getData(), ':');
      },
    };
  }

  let table = new Handsontable(element, settings);

  window.handsontableInstances.push(table);

  $('a[data-toggle="tab"][href="#development-schedule"]').on(
    'shown.bs.tab',
    (event) => table.render(),
  );
};
