import Handsontable from 'handsontable';
import $ from 'jquery';
import afterChangeEventHanlderForMultipleInput from './afterChangeEventHanlderForMultipleInput';

export default (referenceProperties) => {
  const element = document.getElementById('historical-opex-table');
  if (!element) return;

  const inputData = JSON.parse($('#historical-opex-input-data').val());
  const headers = JSON.parse($('#historical-opex-col-headers').val());
  const settings = {
    data: inputData,
    columns: [
      {
        data: 'year',
        type: 'text',
      },
      {
        data: 'nonFPSOFixedOpex',
        type: 'numeric',
        numericFormat: {
          pattern: '0,0.[00000]',
        },
      },
      {
        data: 'histVariableOpex',
        type: 'numeric',
        numericFormat: {
          pattern: '0,0.[00000]',
        },
      },
      {
        data: 'oilTransportationOpex',
        type: 'numeric',
        numericFormat: {
          pattern: '0,0.[00000]',
        },
      },
      {
        data: 'gasTransportationOpex',
        type: 'numeric',
        numericFormat: {
          pattern: '0,0.[00000]',
        },
      },
    ],
    rowHeaders: false,
    colHeaders: headers,
    afterChange: function (event) {
      const temp_result = {
        gasTransportationOpex: [],
        histVariableOpex: [],
        nonFPSOFixedOpex: [],
        oilTransportationOpex: [],
      };
      afterChangeEventHanlderForMultipleInput(
        temp_result,
        this.getSourceData(),
        referenceProperties,
      );
    },
  };

  let table = new Handsontable(element, settings);

  window.handsontableInstances.push(table);

  $('a[data-toggle="tab"][href="#historical-opex"]').on(
    'shown.bs.tab',
    (event) => table.render(),
  );
};
