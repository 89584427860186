import $ from 'jquery';
function unloadPromptMessage(ev) {
  let message = 'You have unsaved changes. Are you sure you want to leave?';
  ev = ev || window.event;

  if (ev) {
    ev.returnValue = message;
  }
  return message;
}

export default (unload) => {
  window.onbeforeunload = unload ? unloadPromptMessage : null;
};
