import $ from 'jquery';
import hideAlert from '../common/hideAlert';
import showAlert from '../common/showAlert';

function disableRunThroughputButton(runThroughputButton) {
  let runThroughputButtonText = 'Pipeline Segment Throughputs importing';
  runThroughputButton.addClass('disabled');
  runThroughputButton.text(runThroughputButtonText);
}

export default (ev) => {
  let btn = $(ev.target).parent();

  if ($(btn).hasClass('disabled')) {
    return;
  }

  $(btn).addClass('disabled');
  let runPipeThroughput = btn.data('from') && btn.data('from') === 'country';

  $.ajax({
    url: $(btn).data('url'),
    method: 'POST',
    success: function (response) {
      showAlert('Pipeline Segment Throughputs started import', 'info');
      setTimeout(hideAlert, 3000, '.admin.alert');
      if (runPipeThroughput)
        disableRunCalulationsButton(
          $('.save-button[data-type="pipe-throughput"]'),
        );
    },
    error: function (response) {
      showAlert(response.responseJSON.message, 'danger');
    },
    complete: function (response) {
      $(btn).removeClass('disabled');
    },
  });
};
