import Handsontable from 'handsontable';
import $ from 'jquery';
export default (countryId) => {
  let element = document.getElementById('facility-ghg-overrides-table');
  if (!element) return;

  const inputData = JSON.parse($('#facility-ghg-overrides-data').val());
  const facilityCol =
    countryId === JSCONSTANT.suriname_id
      ? { type: 'text' }
      : { type: 'text', edittor: false };

  const settings = {
    data: inputData,
    colWidths: [200, ...Array(2).fill(75), ...Array(9).fill(110)],
    colHeaders: [
      'Facility',
      'Start Year',
      'End Year',
      'Gas Low',
      'Gas Base',
      'Gas High',
      'Diesel Low',
      'Diesel Base',
      'Diesel High',
      'Utilization',
      'Oil Processing',
      'Gas Processing',
    ],
    columns: [
      facilityCol,
      ...Array(2).fill({
        type: 'numeric',
      }),
      ...Array(9).fill({
        type: 'numeric',
        numericFormat: {
          pattern: '0,0.[00000000]',
        },
      }),
    ],

    afterChange: function (event) {
      let newData = this.getData()
        .map((subArray) => subArray.join(':'))
        .join(';');
      $(`#facility-ghg-overrides`).val(newData);
    },
  };

  let table = new Handsontable(element, settings);

  $('a[data-toggle="tab"][href="#ghg-asset-inputs"]').on(
    'shown.bs.tab',
    (event) => table.render(),
  );
};
