import Handsontable from 'handsontable';
import $ from 'jquery';
import afterChangeEventHanlderForSingleInput from './afterChangeEventHanlderForSingleInput';

export default (referenceProperties) => {
  const element = document.getElementById('corporate-tax-rate-table');
  if (!element) return;

  const inputData = JSON.parse($('#corporate-tax-rate-input-data').val());

  const settings = {
    data: inputData,
    colWidths: [100, 100],
    colHeaders: ['Year', '%'],
    columns: [
      {
        editor: false,
      },
      {
        type: 'numeric',
        numericFormat: {
          pattern: '0,0.[0]',
        },
      },
    ],

    afterChange: function (event) {
      const propertyId = referenceProperties.corporateTaxRate;

      afterChangeEventHanlderForSingleInput(propertyId, this.getData(), ':');
    },
  };

  let table = new Handsontable(element, settings);
  window.handsontableInstances.push(table);

  $('a[data-toggle="tab"][href="#taxes"]').on('shown.bs.tab', (event) =>
    table.render(),
  );
};
