import Handsontable from 'handsontable';
import $ from 'jquery';
import afterChangeEventHanlderForSingleInput from './afterChangeEventHanlderForSingleInput';

export default (country_id, referenceProperties) => {
  const element = document.getElementById('gas-monetization');
  if (!element) return;

  const inputData = JSON.parse($('#gas-monetization-input-data').val());
  let colHeaders, columns, colWidths;

  switch (country_id) {
    case JSCONSTANT.canada_offshore_id:
    case JSCONSTANT.denmark_id:
    case JSCONSTANT.germany_id:
    case JSCONSTANT.italy_id:
    case JSCONSTANT.netherlands_id:
    case JSCONSTANT.norway_id:
    case JSCONSTANT.romania_id:
    case JSCONSTANT.uk_id:
      colWidths = [100, 100, 100, 100];
      colHeaders = ['Re-injected', 'Flared', 'Consumed', 'Vented'];
      columns = [
        ...Array(4).fill({
          type: 'numeric',
          numericFormat: {
            pattern: '0,0.[00]',
          },
        }),
      ];
      break;
    case JSCONSTANT.angola_id:
    case JSCONSTANT.nigeria_id:
      colWidths = [100, 100, 100, 100];
      colHeaders = ['Re-injected', 'Flared', 'Consumed', 'Monetized'];
      columns = [
        ...Array(4).fill({
          type: 'numeric',
          numericFormat: {
            pattern: '0,0.[00]',
          },
        }),
      ];
      break;
    case JSCONSTANT.namibia_id:
      colWidths = [100];
      colHeaders = ['Re-injected'];
      columns = [
        {
          type: 'numeric',
          numericFormat: {
            pattern: '0,0.[00]',
          },
        },
      ];
      break;
    case JSCONSTANT.ecuador_id:
      colWidths = [100, 100, 100, 100];
      colHeaders = ['Produced', 'Re-injected', 'Flared', 'Consumed'];
      columns = [
        ...Array(4).fill({
          type: 'numeric',
          numericFormat: {
            pattern: '0,0.[00]',
          },
        }),
      ];
      break;
    default: {
      colWidths = [100, 100, 100];
      colHeaders = ['Re-injected', 'Flared', 'Consumed'];
      columns = [
        ...Array(3).fill({
          type: 'numeric',
          numericFormat: {
            pattern: '0,0.[00]',
          },
        }),
      ];
    }
  }

  const settings = {
    data: inputData,
    colWidths: colWidths,
    colHeaders: colHeaders,
    columns: columns,

    afterChange: function (event) {
      const propertyId = referenceProperties.gasMonetization;

      afterChangeEventHanlderForSingleInput(propertyId, this.getData(), ':');
    },
  };

  let table = new Handsontable(element, settings);

  window.handsontableInstances.push(table);
  $('a[data-toggle="tab"][href="#financial-considerations"]').on(
    'shown.bs.tab',
    (event) => table.render(),
  );
};
