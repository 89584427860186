import $ from 'jquery';
import hideAlert from '../common/hideAlert';
import showAlert from '../common/showAlert';

function disableImportCompaniesButton(importCompaniesButton) {
  let importCompaniesButtonText = I18n.t(
    'common.import_companies_in_processing',
  );
  importCompaniesButton.addClass('disabled');
  importCompaniesButton.text(importCompaniesButtonText);
}

export default (ev) => {
  let btn = $(ev.currentTarget);

  if ($(btn).hasClass('disabled')) {
    return;
  }

  $(btn).addClass('disabled');
  let runCalculationsFromCountry =
    btn.data('from') && btn.data('from') === 'country';

  $.ajax({
    url: $(btn).data('url'),
    method: 'POST',
    headers: {
      'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
    },
    success: function (response) {
      showAlert('Importing companies started', 'info');
      setTimeout(hideAlert, 3000, '.admin.alert');
      if (runCalculationsFromCountry)
        disableImportCompaniesButton(
          $('.save-button[data-type="run-calculation"]'),
        );
    },
    error: function (response) {
      showAlert(response.responseJSON.message, 'danger');
    },
    complete: function (response) {
      $('#confirm-import-companies-modal').modal('hide');
      $(btn).removeClass('disabled');
    },
  });
};
