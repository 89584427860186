import Handsontable from 'handsontable';
import $ from 'jquery';
import afterChangeEvent from './afterChangeEvent';

export default () => {
  let element = document.getElementById('wti-oil-prices');
  if (!element) return;

  let inputElement = $('#wti-oil-prices-input-data');
  let propertyId = inputElement.data()['id'];
  let dataSource = JSON.parse(inputElement.val());

  new Handsontable(element, {
    data: dataSource,
    colHeaders: ['Date', 'WTI (US$/bbl)'],
    rowHeaders: false,
    columns: [
      {
        type: 'numeric',
      },
      {
        type: 'numeric',
        numericFormat: {
          pattern: '0,0.[00]',
        },
      },
    ],

    afterChange: function () {
      let updatedData = this.getData();
      afterChangeEvent(propertyId, updatedData, ',');
    },
  });
};
