import $ from 'jquery';
import createDevelopmentPhasesTableInput from './createDevelopmentPhasesTableInput';
import createParticipantTableInput from './createParticipantTableInput';
import createFieldProductionTableInput from './createFieldProductionTableInput';
import createTotalProductionTableInput from './createTotalProductionTableInput';
import createEurUpliftTableInput from './createEurUpliftTableInput';
import createCashFlowTable from './createCashFlowTable';
import createMiscCapexTableInput from './createMiscCapexTableInput';
import createHistWellsTableInput from './createHistWellsTableInput';
import createWelliGasPriceTableInput from './createWelliGasPriceTableInput';
import createPurchasedFacilityCapexBreakdownTableInput from './createPurchasedFacilityCapexBreakdownTableInput';
import importTypeCurve from './importTypeCurve';
import resetTypeCurve from './resetTypeCurve';
import formatProductionYear from './formatProductionYear';
import createHistoricalWellScheduleOverrideTableInput from './createHistoricalWellScheduleOverrideTableInput';
import createHistoricalWellInformationTableInput from './createHistoricalWellInformationTableInput';
import createHistoricalCapexTableInput from './createHistoricalCapexTableInput';
import createFpsoInformationTableInput from './createFpsoInformationTableInput';
import createFpsoFieldTableInput from './createFpsoFieldTableInput';
import createCalculatedWellScheduleTable from './createCalculatedWellScheduleTable';
import createHistoricalOpexTableInput from './createHistoricalOpexTableInput';
import createCapexOtherCostItemsTableInput from './createCapexOtherCostItemsTableInput';
import createGasPercentSplitTableInput from './createGasPercentSplitTableInput';
import createBonusTableInput from './createBonusTableInput';
import createRoyaltyTableInput from './createRoyaltyTableInput';
import createGasMonetizationTableInput from './createGasMonetizationTableInput';
import createGasMonetizationVerticalTableInput from './createGasMonetizationVerticalTableInput';
import createGasMonetizationAndTaxTableInput from './createGasMonetizationAndTaxTableInput';
import createHistoricalCapex2TableInput from './createHistoricalCapex2TableInput';
import createIorUpliftTableInput from './createIorUpliftTableInput';
import createOilPricesTableInput from './createOilPricesTableInput';
import createGasPricesTableInput from './createGasPricesTableInput';
import createTightOilTableInput from './createTightOilTableInput';
import createTightGasTableInput from './createTightGasTableInput';
import createShaleGasTableInput from './createShaleGasTableInput';
import createAverageWellCostsTableInput from './createAverageWellCostsTableInput';
import createPdpDeclineRatesTableInput from './createPdpDeclineRatesTableInput';
import createSidetrackScheduleTableInput from './createSidetrackScheduleTableInput';
import createWellScheduleTableInput from './createWellScheduleTableInput';
import createOpexOtherCostItemsTableInput from './createOpexOtherCostItemsTableInput';
import createHistoricalPriceTable from './createHistoricalPriceTable';
import createServiceTariffTableInput from './createServiceTariffTableInput';
import createServiceTariffWeightsTableInput from './createServiceTariffWeightsTableInput';
import createOilExportedProductionTableInput from './createOilExportedProductionTableInput';
import createHistoricalCapex3TableInput from './createHistoricalCapex3TableInput';
import createHistoricalOpex2TableInput from './createHistoricalOpex2TableInput';
import createHistoricalOpex3TableInput from './createHistoricalOpex3TableInput';
import createGasProductionTableInput from './createGasProductionTableInput';
import createLiquidsProductionTableInput from './createLiquidsProductionTableInput';
import createLiquidsPriceTableInput from './createLiquidsPriceTableInput';
import createFacilityInformationTableInput from './createFacilityInformationTableInput';
import createHistoricalCapex4TableInput from './createHistoricalCapex4TableInput';
import createAdditionalProductionRecoveryTableInput from './createAdditionalProductionRecoveryTableInput';
import createMarketRatioOverridesTableInput from './createMarketRatioOverridesTableInput';
import createExportsMarketPriceTableInput from './createExportsMarketPriceTableInput';
import createLngContractedPriceTableInput from './createLngContractedPriceTableInput';
import createLngShippingCostsTableInput from './createLngShippingCostsTableInput';
import createHistoricalDrillingTableInput from './createHistoricalDrillingTableInput';
import createMultipleGasPropertiesTable from './createMultipleGasPropertiesTable';
import createCurrentGasPriceInputTable from './createCurrentGasPriceInputTable';
import createCurrentGasSplitInputTable from './createCurrentGasSplitInputTable';
import createPopulateMarketTableInput from './createPopulateMarketTableInput';
import createGasSplitUndevelopedProductionTable from './createGasSplitUndevelopedProductionTable';
import createRProductionOverrideTable from './createRProductionOverrideTable';
import createTariffReceivable from './createTariffReceivable';
import createLiquidsPricingDifferentialstTable from './createLiquidsPricingDifferentialstTable';
import createLiquidsPricingTransportationTable from './createLiquidsPricingTransportationTable';
import createLiquidsPricingMarketTable from './createLiquidsPricingMarketTable';
import createGasPricingDifferentialstTable from './createGasPricingDifferentialstTable';
import createGasPricingTransportationTable from './createGasPricingTransportationTable';
import createGasPricingMarketTable from './createGasPricingMarketTable';
import createLngProductionTable from './createLngProductionTable';
import createHistoricalGasMonetizationTableInput from './createHistoricalGasMonetizationTableInput';
import createCapexGasLngTableInput from './createCapexGasLngTableInput';
import createFeedGasProductionTableInput from './createFeedGasProductionTableInput';
import createLngSpotPriceTableInput from './createLngSpotPriceTableInput';
import initReportNotes from '../common/initReportNoteInputs';
import createNorwayMarketTableInput from './createNorwayMarketTableInput';

// Asset GHG Inputs
import createAssetGhgPropertiesTable from './ghg_inputs/createAssetGhgPropertiesTable';
import createScope3InputsTable from './ghg_inputs/createScope3InputsTable';
import createAssetGhgOverridesTable from './ghg_inputs/createAssetGhgOverridesTable';
import createAssetGasCompositionsTable from './ghg_inputs/createAssetGasCompositionsTable';
import createMachineUtilizationGasTable from './ghg_inputs/createMachineUtilizationGasTable';
import createFacilityGhgOverridesTable from './ghg_inputs/createFacilityGhgOverridesTable';
import createFacilityGroupOverridesTable from './ghg_inputs/createFacilityGroupOverridesTable';
import createHistFuelOverridesTable from './ghg_inputs/createHistFuelOverridesTable';
import createHistFlaringOverrideTable from './ghg_inputs/createHistFlaringOverrideTable';
import createFutureConsumptionSplitTable from './ghg_inputs/createFutureConsumptionSplitTable';
import createRenewableEnergyProjectsTable from './ghg_inputs/createRenewableEnergyProjectsTable';
import createInFieldFuelConsumptionTable from './ghg_inputs/createInFieldFuelConsumptionTable';
import createPurchasedElectricityConsumptionSplitTable from './ghg_inputs/createPurchasedElectricityConsumptionSplitTable';
import createAssetProcessingOverridesTable from './ghg_inputs/createAssetProcessingOverridesTable';
import createAssetGhgPowerInputsTable from './ghg_inputs/createAssetGhgPowerInputsTable';
import createAssetGhgElectrificationInputsTable from './ghg_inputs/createAssetGhgElectrificationInputsTable';
import createAssetGhgFlaringInputsTable from './ghg_inputs/createAssetGhgFlaringInputsTable';

export default (countryId, type = 'Field', options = {}) => {
  $('.ckeditor').each(function () {
    const editorConfig = $(this).data('ckeditor');
    CKEDITOR.replace(this.id, {
      customConfig: '/ckeditor/config.js',
      ...editorConfig,
    });
  });
  if (typeof window.handsontableInstances == 'undefined') {
    window.handsontableInstances = [];
  } else {
    window.handsontableInstances.forEach((tableInstance) => {
      tableInstance.destroy();
    });
    window.handsontableInstances = [];
  }
  try {
    createParticipantTableInput();
    if (type === 'WellBlock') {
      return;
    }

    countryId = parseInt(countryId);
    const referenceProperties = JSON.parse($('#reference-properties').val());

    createDevelopmentPhasesTableInput(countryId, referenceProperties);
    createFieldProductionTableInput(referenceProperties);
    createTotalProductionTableInput(referenceProperties);
    createEurUpliftTableInput(countryId, 'by-value', referenceProperties);
    createEurUpliftTableInput(countryId, 'by-percent', referenceProperties);
    createGasMonetizationAndTaxTableInput(referenceProperties);

    if (!!!options['new_asset']) {
      createCashFlowTable();
    }
    if (JSCONSTANT.render_misc_capex_country.includes(countryId)) {
      createMiscCapexTableInput(referenceProperties);
    }
    if (JSCONSTANT.render_hist_wells_country.includes(countryId)) {
      createHistWellsTableInput(referenceProperties);
    }
    createWelliGasPriceTableInput();
    if (
      JSCONSTANT.purchased_facility_capex_breakdown_country.includes(countryId)
    ) {
      createPurchasedFacilityCapexBreakdownTableInput(referenceProperties);
    }
    importTypeCurve();
    resetTypeCurve();
    formatProductionYear();
    if (
      JSCONSTANT.countryHasHistoricalWellScheduleOverride.includes(countryId)
    ) {
      createHistoricalWellScheduleOverrideTableInput(
        countryId,
        referenceProperties,
      );
    }
    if (
      type === 'Block' &&
      JSCONSTANT.countryHasWellInformationHistoricalDrilling.includes(countryId)
    ) {
      createHistoricalWellInformationTableInput(countryId, referenceProperties);
    }
    if (
      countryId !== JSCONSTANT.argentina_id &&
      countryId !== JSCONSTANT.trinidad_id &&
      countryId !== JSCONSTANT.cuba_id &&
      countryId !== JSCONSTANT.bolivia_id
    ) {
      createHistoricalCapexTableInput(countryId, referenceProperties);
    }
    if (!JSCONSTANT.countryNoRenderFpsoInformation.includes(countryId)) {
      createFpsoInformationTableInput(countryId, referenceProperties);
    }

    if (
      (countryId !== JSCONSTANT.argentina_id &&
        countryId !== JSCONSTANT.trinidad_id &&
        countryId !== JSCONSTANT.cuba_id) ||
      type !== 'Field'
    ) {
      createWellScheduleTableInput(countryId, referenceProperties);
      if (JSCONSTANT.render_caclculated_well_schedule.includes(countryId)) {
        createCalculatedWellScheduleTable();
      }
      if (!JSCONSTANT.not_render_historical_opex_country.includes(countryId)) {
        createHistoricalOpexTableInput(referenceProperties);
      }
      createCapexOtherCostItemsTableInput(referenceProperties);
      createOpexOtherCostItemsTableInput(referenceProperties);
    }
    if (JSCONSTANT.countryHasHistoricalPriceOverride.includes(countryId)) {
      createHistoricalPriceTable(countryId, referenceProperties);
    }

    if (
      (type === 'Block' &&
        JSCONSTANT.countryHasGasMonetizationVerticalTableIds.includes(
          countryId,
        )) ||
      (type === 'Field' && countryId === JSCONSTANT.falkland_id)
    ) {
      createGasMonetizationVerticalTableInput(referenceProperties);
    }

    if (JSCONSTANT.countryHasGasMonetizationTableIds.includes(countryId)) {
      if (type === 'Block') {
        createGasMonetizationTableInput(countryId, referenceProperties);
      }
    }

    if (
      JSCONSTANT.country_build_multi_input_gas_price_to_table.includes(
        countryId,
      )
    ) {
      createMultipleGasPropertiesTable(countryId, 'gas-split');
      if (countryId !== JSCONSTANT.tunisia_id) {
        createMultipleGasPropertiesTable(countryId, 'gas-prices');
      }
    }

    if (type === 'Block') {
      createOilPricesTableInput(referenceProperties);

      if (
        JSCONSTANT.country_build_gas_production_to_table.includes(countryId)
      ) {
        createGasProductionTableInput(countryId, referenceProperties);
      }

      if (
        JSCONSTANT.country_build_liquids_production_to_table.includes(countryId)
      ) {
        createLiquidsProductionTableInput(countryId, referenceProperties);
      }

      if (
        JSCONSTANT.country_build_gas_price_gas_split_table.includes(countryId)
      ) {
        createCurrentGasPriceInputTable(countryId, referenceProperties);
        if (
          countryId !== JSCONSTANT.qatar_id &&
          countryId !== JSCONSTANT.alaska_id
        ) {
          createCurrentGasSplitInputTable(referenceProperties);
        }
      }

      if (
        JSCONSTANT.country_with_r_production_override_table.includes(countryId)
      ) {
        createRProductionOverrideTable(referenceProperties);
      }
    }

    // GHG Inputs tab
    createAssetGhgPropertiesTable();
    createScope3InputsTable();
    createAssetGhgOverridesTable(countryId);
    createAssetGasCompositionsTable('asset');
    createAssetGasCompositionsTable('flaring');
    createAssetGasCompositionsTable('venting');
    createMachineUtilizationGasTable(countryId);
    createFacilityGhgOverridesTable(countryId);
    createFacilityGroupOverridesTable(countryId);
    createHistFuelOverridesTable();
    createFutureConsumptionSplitTable();
    createRenewableEnergyProjectsTable();
    createInFieldFuelConsumptionTable();
    createPurchasedElectricityConsumptionSplitTable();
    createAssetProcessingOverridesTable(countryId);
    createAssetGhgPowerInputsTable();
    createAssetGhgElectrificationInputsTable();
    createAssetGhgFlaringInputsTable();
    createHistFlaringOverrideTable();

    if (JSCONSTANT.countryHasGasPopulateMarket.includes(countryId)) {
      if (type === 'Block') {
        createPopulateMarketTableInput(referenceProperties);
      }
    }

    initReportNotes();

    switch (countryId) {
      case JSCONSTANT.argentina_id:
        createGasPercentSplitTableInput(referenceProperties);
        if (type === 'Block') {
          createBonusTableInput(referenceProperties);
          createRoyaltyTableInput(countryId, referenceProperties);
          createGasMonetizationTableInput(countryId, referenceProperties);
          createHistoricalCapex2TableInput(referenceProperties);
          createIorUpliftTableInput(referenceProperties);
          createGasPricesTableInput(countryId, referenceProperties);
          createTightOilTableInput(referenceProperties);
          createTightGasTableInput(referenceProperties);
          createShaleGasTableInput(referenceProperties);
          createAverageWellCostsTableInput(referenceProperties);
          createOilExportedProductionTableInput(referenceProperties);
        }
        break;
      case JSCONSTANT.trinidad_id:
        if (type === 'Block') {
          createTariffReceivable(referenceProperties);
          createHistoricalCapex3TableInput(referenceProperties);
          createHistoricalOpex2TableInput(referenceProperties);
          createLiquidsPriceTableInput(referenceProperties);
          createPdpDeclineRatesTableInput(referenceProperties);
          createMarketRatioOverridesTableInput(referenceProperties);
          createHistoricalDrillingTableInput(countryId, referenceProperties);
        }
        break;
      case JSCONSTANT.cuba_id:
        if (type === 'Block') {
          createHistoricalCapex3TableInput(referenceProperties);
          createHistoricalOpex2TableInput(referenceProperties);
          createLiquidsPriceTableInput(referenceProperties);
          createFacilityInformationTableInput(referenceProperties);
        }
        break;
      case JSCONSTANT.ecuador_id:
        if (type === 'Block') {
          createServiceTariffTableInput(referenceProperties);
          createServiceTariffWeightsTableInput(referenceProperties);
          createPdpDeclineRatesTableInput(referenceProperties);
          createGasPricesTableInput(countryId, referenceProperties);
        }
        break;
      case JSCONSTANT.GOM_ID:
        if (type === 'Block') {
          createSidetrackScheduleTableInput(referenceProperties);
        }
        break;
      case JSCONSTANT.bolivia_id:
        if (type === 'Block') {
          createHistoricalOpex3TableInput(referenceProperties);
          createHistoricalCapex4TableInput(countryId, referenceProperties);
        }
        break;
      case JSCONSTANT.brazil_id:
        if (type === 'Field') {
          createAdditionalProductionRecoveryTableInput(referenceProperties);
          createRoyaltyTableInput(countryId, referenceProperties);
        }
        break;
      case JSCONSTANT.guyana_id:
        if (type === 'Block') {
          createHistoricalDrillingTableInput(countryId, referenceProperties);
          createFpsoFieldTableInput(countryId, referenceProperties);
        }
        break;
      case JSCONSTANT.mozambique_id:
        if (type == 'Block') {
          createLngProductionTable(referenceProperties);
          createExportsMarketPriceTableInput(referenceProperties);
          createLngContractedPriceTableInput(referenceProperties);
          createLngShippingCostsTableInput(referenceProperties);
          createHistoricalGasMonetizationTableInput(
            countryId,
            referenceProperties,
          );
        }
        break;
      case JSCONSTANT.norway_id:
        if (type == 'Block') {
          createGasSplitUndevelopedProductionTable(
            countryId,
            referenceProperties,
          );
          createNorwayMarketTableInput(referenceProperties);
        }
        break;
      case JSCONSTANT.australia_id:
      case JSCONSTANT.indonesia_id:
      case JSCONSTANT.malaysia_id:
      case JSCONSTANT.papua_new_guinea_id:
      case JSCONSTANT.qatar_id:
      case JSCONSTANT.tanzania_id:
      case JSCONSTANT.malaysia_thailand_jda_id:
        if (type == 'Block') {
          createExportsMarketPriceTableInput(referenceProperties);
          createLngContractedPriceTableInput(referenceProperties);
          createLngShippingCostsTableInput(referenceProperties);
        }
      case JSCONSTANT.kurdistan_id:
        if (type == 'Block') {
          createLiquidsPriceTableInput(referenceProperties);
        }
        break;
      case JSCONSTANT.kazakhstan_id:
        if (type == 'Block') {
          createLiquidsPricingDifferentialstTable(
            countryId,
            referenceProperties,
          );
          createLiquidsPricingTransportationTable(
            countryId,
            referenceProperties,
          );
          createLiquidsPricingMarketTable(countryId, referenceProperties);
          createGasPricingDifferentialstTable(countryId, referenceProperties);
          createGasPricingTransportationTable(countryId, referenceProperties);
          createGasPricingMarketTable(countryId, referenceProperties);
        }
        break;
      case JSCONSTANT.congo_id:
        if (type == 'Block') {
          createFeedGasProductionTableInput(referenceProperties);
          createCapexGasLngTableInput(referenceProperties);
          createExportsMarketPriceTableInput(referenceProperties);
          createLngContractedPriceTableInput(referenceProperties);
          createLngShippingCostsTableInput(referenceProperties);
          createHistoricalGasMonetizationTableInput(
            countryId,
            referenceProperties,
          );
        }
        break;
      case JSCONSTANT.russia_id:
        if (type == 'Block') {
          createLngContractedPriceTableInput(referenceProperties);
          createLngSpotPriceTableInput(referenceProperties);
          createLngShippingCostsTableInput(referenceProperties);
          createLiquidsPricingDifferentialstTable(
            countryId,
            referenceProperties,
          );
          createLiquidsPricingTransportationTable(
            countryId,
            referenceProperties,
          );
          createLiquidsPricingMarketTable(countryId, referenceProperties);
          createGasPricingDifferentialstTable(countryId, referenceProperties);
          createGasPricingTransportationTable(countryId, referenceProperties);
          createGasPricingMarketTable(countryId, referenceProperties);
        }
        break;
    }
  } catch (error) {
    console.log(error);
  }
};
