import Handsontable from 'handsontable';
import $ from 'jquery';
import afterChangeEventHanlderForSingleInput from './afterChangeEventHanlderForSingleInput';

export default (referenceProperties) => {
  let element = document.getElementById('tariff-receivable-table');

  if (!element) return;

  const inputData = JSON.parse($('#tariff-receivable-data').val());
  const settings = {
    data: inputData,
    colHeaders: ['Year', 'US$MM'],
    colWidths: [100, 100],
    columns: [
      {
        editor: false,
      },
      {
        type: 'text',
        placeholder: 'NA',
      },
    ],
    maxRows: JSCONSTANT.handsontable.wellScheduleRows,
    afterChange: function (event) {
      const propertyId = referenceProperties.tariffReceivable;

      afterChangeEventHanlderForSingleInput(propertyId, this.getData(), ':');
    },
  };

  let table = new Handsontable(element, settings);

  window.handsontableInstances.push(table);

  $('a[data-toggle="tab"][href="#financial_considerations"]').on(
    'shown.bs.tab',
    (event) => table.render(),
  );
};
