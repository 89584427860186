import Handsontable from 'handsontable';
import $ from 'jquery';
export default () => {
  const element = document.getElementById('cash-flow-table');
  if (!element) return;
  const inputData = JSON.parse($('#cash-flow-input-data').val());
  const settings = {
    data: inputData,
    rowHeaders: false,
    readOnly: true,
    disableVisualSelection: true,
  };

  let table = new Handsontable(element, settings);
  window.handsontableInstances.push(table);

  $('a[data-toggle="tab"][href="#financial-considerations"]').on(
    'shown.bs.tab',
    (event) => table.render(),
  );
};
