import $ from 'jquery';

export default function (_) {
  $('[data-action="open_page"]').click(function (ev) {
    ev.stopPropagation();
    window.location.href = $(this).data('href');
  });

  const offlineFiles = document.querySelector('.offline-files');
  if (!offlineFiles) return;
  if (window.initCollapsibleUploadSection) return;

  window.offlineFilesHeight = `${offlineFiles.offsetHeight}px`;
  const defaultHeight = '400px';
  offlineFiles.style.height = defaultHeight;
  $('.view-button').on('click', function () {
    if (offlineFiles.style.height === defaultHeight) {
      offlineFiles.style.height = window.offlineFilesHeight;
      $('.view-button span').text('Collapse');
    } else {
      offlineFiles.style.height = defaultHeight;
      $('.view-button span').text('Expand');
    }
  });
  window.initCollapsibleUploadSection = true;
}
