import consumer from './consumer';

consumer.subscriptions.create(
  { channel: 'WorkerEventChannel', room: 'Worker Event Room' },
  {
    received(payload) {
      if (location.pathname !== '/admin/worker_events/stream') return;
      const filter = this.filter();
      if (
        (filter.worker && filter.worker !== payload.body.worker) ||
        (filter.countryId && filter.countryId !== payload.body.country_id)
      )
        return;

      this.prependLine(payload);
    },
    prependLine(payload) {
      const severity = ['normal', 'high'].includes(payload.body.severity)
        ? 'status-bad'
        : '';
      let state = '';
      switch (payload.body.state) {
        case 'completed':
          state = 'status-ok';
          break;
        case 'failed':
          state = 'status-bad';
          break;
      }
      this.fetchWorkerTable()
        .querySelector('.wt-head')
        .insertAdjacentHTML(
          'afterend',
          `<div class="wt-item intro">
  <div class="cell">
    <a href="/admin/worker_events/${payload.body.id}">${payload.body.worker}</a>
  </div>
  <div class="cell">
    <div>${payload.countryName}</div>
    <div>
      <span>Status:</span>
      <span class="${state}">${payload.body.state}</span>
    </div>
    <div>
      <span>Severity:</span>
      <span class="${severity}">${payload.body.severity}</span>
    </div>
    <div>${payload.createdTime}</div>
  </div>
  <div class="cell">
    <div class="meta-message">${payload.body.notes}</div>
    <div>Metainfo:</div>
    <div class="meta-info">${payload.metaMessage}</div>
  </div>
</div>`
        );
      this.fetchWorkerTable()
        .querySelectorAll('.wt-item')
        .forEach((el, index) => {
          if (index < 20) return;

          el.remove();
        });
    },
    fetchWorkerTable() {
      return document.querySelector('.workers-table.worker-events.stream');
    },
    filter() {
      const filter = new URLSearchParams(location.search);
      const countryId = filter.get('worker_events_search[country_id]');
      return {
        worker: filter.get('worker_events_search[worker]'),
        countryId: countryId ? parseInt(countryId, 10) : undefined,
      };
    },
  }
);
