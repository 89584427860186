import Handsontable from 'handsontable';
import $ from 'jquery';
import afterChangeEventHanlderForSingleInput from './afterChangeEventHanlderForSingleInput';

export default (referenceProperties) => {
  const element = document.getElementById('exports-market-price-table');
  const inputData = JSON.parse($('#exports-market-price-input-data').val());

  const settings = {
    data: inputData,
    colWidths: [250, 100],
    colHeaders: ['Domestic/Piped Exports Market Price', ''],
    columns: [
      {
        editor: false,
      },
      {
        type: 'numeric',
        numericFormat: {
          pattern: '0,0.[00]',
        },
      },
    ],

    afterChange: function (event) {
      const propertyId = referenceProperties.exportsMarketPrice;

      afterChangeEventHanlderForSingleInput(propertyId, this.getData(), ':');
    },
  };

  let table = new Handsontable(element, settings);

  window.handsontableInstances.push(table);

  $('a[data-toggle="tab"][href="#financial-considerations"]').on(
    'shown.bs.tab',
    (event) => table.render(),
  );
};
