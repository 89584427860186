import Handsontable from 'handsontable';
import $ from 'jquery';
const afterChangeEventHanlderForGasPricesInput = (updatedData) => {
  const newValue = updatedData.map((subArray) => subArray.join(',')).join(';');

  $('#welli_gas_prices_field').val(newValue);
};

export default () => {
  const element = document.getElementById('welli-gas-prices-5-table');

  if (!element) return;

  const inputData = JSON.parse($('#welli-gas-prices-5-input-data').val());

  const settings = {
    data: inputData,
    rowHeaders: false,
    afterChange: function (event) {
      afterChangeEventHanlderForGasPricesInput(this.getData());
    },
  };

  let table = new Handsontable(element, settings);

  window.handsontableInstances.push(table);

  $('a[data-toggle="tab"][href="#financial-considerations"]').on(
    'shown.bs.tab',
    (event) => table.render(),
  );
};
