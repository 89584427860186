import Handsontable from 'handsontable';
import $ from 'jquery';
import afterChangeEventHanlderForSingleInput from './afterChangeEventHanlderForSingleInput';

export default (country_id, referenceProperties) => {
  const element = document.getElementById('fpso-information-table');
  if (!element) return;

  const inputData = JSON.parse($('#fpso-information-input-data').val());
  const settings = {
    data: inputData,
    rowHeaders: false,
    colWidths: [70, 70, 70, 70, 70, 70, 70, 70, 70, 70],
    cells(row, column) {
      const cellMeta = {};

      switch (row) {
        case 0:
          if (column > 0 && column < 10) {
            cellMeta.type = 'dropdown';
            cellMeta.source = JSCONSTANT.fpsoInformationDropdown;
          } else if (
            column === 8 &&
            country_id !== JSCONSTANT.cote_d_ivoire_id
          ) {
            cellMeta.editor = false;
          }
          break;
        case 1:
        case 3:
          if (column > 0 && column < 10) {
            cellMeta.type = 'numeric';
          }
          break;
      }

      return cellMeta;
    },
    afterChange: function (event) {
      const propertyId = referenceProperties.fPSOInformation;
      afterChangeEventHanlderForSingleInput(propertyId, this.getData(), ',');
    },
  };

  let table = new Handsontable(element, settings);
  window.handsontableInstances.push(table);

  $('a[data-toggle="tab"][href="#development-schedule"]').on(
    'shown.bs.tab',
    (event) => table.render(),
  );
};
