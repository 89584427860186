import Handsontable from 'handsontable';
import $ from 'jquery';
import afterChangeEventHanlderForMultipleInput from './afterChangeEventHanlderForMultipleInput';

export default (referenceProperties) => {
  const element = document.getElementById(
    'tight-gas-well-characteristics-table',
  );
  if (!element) return;

  const inputData = JSON.parse($('#tight-gas-input-data').val());
  const settings = {
    data: inputData,
    columns: [
      {
        data: 'year',
        type: 'text',
      },
      {
        data: 'noOfFrackStagesTightGas',
        type: 'numeric',
        numericFormat: {
          pattern: '0,0.[00000]',
        },
      },
      {
        data: 'lateralLengthTightGas',
        type: 'numeric',
        numericFormat: {
          pattern: '0,0.[00000]',
        },
      },
      {
        data: 'wellSpacingTightGas',
        type: 'numeric',
        numericFormat: {
          pattern: '0,0.[00000]',
        },
      },
    ],
    rowHeaders: false,
    colWidths: [100, 150, 150, 150],
    colHeaders: [
      'Year',
      'No. of frack stages',
      'Lateral Length (m)',
      'Well Spacing (acres)',
    ],

    afterChange: function (event) {
      const temp_result = {
        noOfFrackStagesTightGas: [],
        lateralLengthTightGas: [],
        wellSpacingTightGas: [],
      };

      afterChangeEventHanlderForMultipleInput(
        temp_result,
        this.getSourceData(),
        referenceProperties,
      );
    },
  };

  let table = new Handsontable(element, settings);

  window.handsontableInstances.push(table);

  $('a[data-toggle="tab"][href="#Unconventional"]').on(
    'shown.bs.tab',
    (event) => table.render(),
  );
};
