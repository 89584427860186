import $ from 'jquery';
import hideAlert from '../common/hideAlert';
import showAlert from '../common/showAlert';

export default (ev) => {
  let btn = $(ev.target).parent();

  if ($(btn).hasClass('disabled')) {
    return;
  }

  $(btn).addClass('disabled');

  $.ajax({
    url: $(btn).data('url'),
    method: 'GET',
    success: function (response) {
      showAlert(
        'Core report is currently being written. Please check the core-report-check channel on Slack for the status of report!',
        'info',
      );
      setTimeout(hideAlert, 5000, '.admin.alert');

      $(btn).removeClass('disabled');
    },
    error: function (response) {
      showAlert(
        'Core template is not avaiable. Please upload the template and then generate core report',
        'danger',
      );
      setTimeout(hideAlert, 5000, '.admin.alert');
    },
  });
};
