import $ from 'jquery';
function scrollToTop() {
  $('html, body').animate({ scrollTop: 0 }, 300);
}

function convertMessage(raw_message) {
  if (Object.prototype.toString.call(raw_message) === '[object Array]') {
    return buildMessagesList(raw_message);
  } else {
    return raw_message;
  }
}

function buildMessagesList(messagesList) {
  let list = $('<ul>');

  $.map(messagesList, function (message) {
    list.append($('<li>').text(message));
  });

  return list;
}

export default (message, messageType) => {
  let alert = '.admin.alert.alert-' + messageType;

  $(alert).html(convertMessage(message));
  $(alert).slideDown('slow');
  scrollToTop();
};
