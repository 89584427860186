import setUnloadConfirmation from './setUnloadConfirmation';
import $ from 'jquery';

export default (form) => {
  $(form)
    .find('input[type="text"], textarea, select')
    .change(function (ev) {
      setUnloadConfirmation(true);
    });
};
