import Handsontable from 'handsontable';
import $ from 'jquery';
import _ from 'lodash';

export const updateInput = (propName, updatedData) => {
  const newValue = updatedData.map((subArray) => subArray.join(':')).join(';');

  $(`#property_${_.snakeCase(propName)}`).val(newValue);
};

export default (country_id, propName) => {
  const element = document.getElementById(`${propName}-table`);
  if (!element) {
    return;
  }
  const inputData = JSON.parse($(`#${propName}-input-data`).val());
  let colHeaders, columns, colWidths;

  if (JSCONSTANT.COUNTRIES_HIDE_COLUMN_NGL_LPG.includes(country_id)) {
    colHeaders = ['LNG', 'Residential', 'Industrial'];
    columns = [
      ...Array(3).fill({
        type: 'numeric',
        numericFormat: {
          pattern: '0,0.[00]',
        },
      }),
    ];
    colWidths = [100, 100, 100];
  } else if (JSCONSTANT.country_has_one_column_gas_price.includes(country_id)) {
    colHeaders = ['Residential'];
    columns = [
      ...Array(1).fill({
        type: 'numeric',
        numericFormat: {
          pattern: '0,0.[00]',
        },
      }),
    ];
    colWidths = [100];
  } else if (country_id == JSCONSTANT.cyprus_id) {
    colHeaders = ['Domestic', 'Exports'];
    columns = [
      ...Array(2).fill({
        type: 'numeric',
        numericFormat: {
          pattern: '0,0.[00]',
        },
      }),
    ];
    colWidths = [100, 100];
  } else if (country_id == JSCONSTANT.morocco_id) {
    colHeaders = ['Spain', 'Residential', 'Industrial'];
    columns = [
      ...Array(3).fill({
        type: 'numeric',
        numericFormat: {
          pattern: '0,0.[00]',
        },
      }),
    ];
    colWidths = [100, 100, 100];
  } else if (country_id == JSCONSTANT.brunei_id) {
    colHeaders = ['LNG', 'Domestic'];
    columns = [
      ...Array(2).fill({
        type: 'numeric',
        numericFormat: {
          pattern: '0,0.[00]',
        },
      }),
    ];
    colWidths = [100, 100];
  } else {
    colHeaders = ['LNG', 'Residential', 'Industrial', 'NGL/LPG'];
    columns = [
      ...Array(4).fill({
        type: 'numeric',
        numericFormat: {
          pattern: '0,0.[00]',
        },
      }),
    ];
    colWidths = [100, 100, 100, 100];
  }

  const settings = {
    data: inputData,
    colWidths,
    colHeaders,
    columns,
    maxRows: 1,

    afterChange: function (event) {
      updateInput(propName, this.getData());
    },
  };

  let table = new Handsontable(element, settings);
  window.handsontableInstances.push(table);

  $('a[data-toggle="tab"][href="#financial-considerations"]').on(
    'shown.bs.tab',
    (event) => table.render(),
  );
};
