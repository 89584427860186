import $ from 'jquery';
export default function initClientDownloadLimit() {
  $('input#custom_download_limit').on('change', (e) => {
    let textFieldInput = $(
      `.user-download-limit-input[data-user-id=${e.target.dataset.userId}]`,
    );
    let hiddenInput = $(
      `#hidden_user_download_limit_${e.target.dataset.userId}`,
    );

    $(textFieldInput).prop('disabled', !e.target.checked);
    if (!e.target.checked) {
      $(textFieldInput).val(null);
      $(textFieldInput).attr('value', null);
      $(hiddenInput).attr('value', null);
    } else {
      $(textFieldInput).attr('value', $(hiddenInput).attr('value'));
    }
  });

  $('input.user-download-limit-input').on('change', (e) => {
    let userId = e.target.dataset.userId;
    let hiddenInput = $(`#hidden_user_download_limit_${userId}`);
    let saveButton = $(`.save-download-limit[data-user-id=${userId}]`);
    hiddenInput.attr('value', e.target.value);
    if (hiddenInput.val() == hiddenInput[0].dataset.originalValue)
      saveButton.addClass('disabled');
    else saveButton.removeClass('disabled');
  });

  $('.hidden_user_download_limit').on('change', (e) => {});

  $('.save-download-limit').on('click', (e) => {
    let userId = e.target.dataset.userId;
    let hiddenInput = $(`#hidden_user_download_limit_${userId}`);
    if (hiddenInput.val() != hiddenInput.data('originalValue')) {
      $.post({
        url: `/admin/users/${userId}/update_download_limit`,
        data: {
          id: e.target.dataset.userId,
          client_id: /clients\/(\d+)/.exec(location.pathname)[1],
          downloads_limit: hiddenInput.val(),
        },
        success: (response) => {
          let hiddenInput = $(
            `#hidden_user_download_limit_${e.target.dataset.userId}`,
          );
          hiddenInput.data('originalValue', response['downloads_limit']);
        },
      });
    }
  });
}
