import $ from 'jquery';
import hideAlert from '../common/hideAlert';
import showAlert from '../common/showAlert';

export default (ev) => {
  let btn = $(ev.target).parent();

  if ($(btn).hasClass('disabled')) {
    return;
  }

  $(btn).addClass('disabled');

  $.ajax({
    url: $(btn).data('url'),
    method: 'POST',
    success: function (response) {
      showAlert('TC Update has been updated', 'info');
      setTimeout(hideAlert, 3000, '.admin.alert');
    },
    error: function (response) {
      showAlert(response.responseJSON.message, 'danger');
    },
    complete: function (response) {
      $(btn).removeClass('disabled');
    },
  });
};
