import Handsontable from 'handsontable';
import $ from 'jquery';
import afterChangeEventHanlderForSingleInput from './afterChangeEventHanlderForSingleInput';

export default (referenceProperties) => {
  const element = document.getElementById('lng-production-table');
  if (!element) return;

  const inputData = JSON.parse($('#lng-production-input-data').val());
  const settings = {
    data: inputData,
    rowHeaders: false,
    colWidths: [100, 150, 150, 150, 200, 200],
    afterChange: function (event) {
      const propertyId = referenceProperties.lNGProductionPhases;
      afterChangeEventHanlderForSingleInput(propertyId, this.getData(), ':');
    },
  };

  let table = new Handsontable(element, settings);
  window.handsontableInstances.push(table);

  $('a[data-toggle="tab"][href="#production"]').on('shown.bs.tab', (event) =>
    table.render(),
  );
};
