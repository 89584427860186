import Handsontable from 'handsontable';
import $ from 'jquery';
const afterChangeEventHanlderForCapexInput = (
  propertyId,
  updatedData,
  separator,
) => {
  const newValue = updatedData.map((element) => element[1]).join(separator);

  return $(`#property_${propertyId}`).val(newValue);
};

export default (referenceProperties) => {
  const element = document.getElementById(
    'purchased-facility-capex-breakdown-table',
  );
  if (!element) return;

  let inputData = JSON.parse(
    $('#purchased-facility-capex-breakdown-data').val(),
  );

  inputData = inputData.map((element, index) => [
    JSCONSTANT.capexTable[index],
    element,
  ]);

  const settings = {
    data: inputData,
    colHeaders: ['Purchased FPSO CAPEX Breakdown', 'Percentage (%) '],
    columns: [
      {
        editor: false,
      },
      {
        type: 'numeric',
        numericFormat: {
          pattern: '0,0',
        },
        placeholder: 'N/A',
      },
    ],
    afterChange: function (event) {
      const propertyId = referenceProperties.purchasedFacilityCapexBreakdown;

      afterChangeEventHanlderForCapexInput(propertyId, this.getData(), ';');
    },
  };

  let table = new Handsontable(element, settings);

  window.handsontableInstances.push(table);

  $('a[data-toggle="tab"][href="#financial-considerations"]').on(
    'shown.bs.tab',
    (event) => table.render(),
  );
};
