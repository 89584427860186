import Handsontable from 'handsontable';
import $ from 'jquery';
import afterChangeEventHanlderForSingleInput from './afterChangeEventHanlderForSingleInput';

export default (referenceProperties) => {
  const gasPriceElement = document.getElementById('norway-gas-prices-table');
  const lngPriceElement = document.getElementById('asia-lng-prices-table');

  if (!gasPriceElement || !lngPriceElement) return;

  const inputData = JSON.parse($('#norway-gas-prices-input-data').val());

  const settings = (propName) => ({
    data: inputData[propName],
    columns: [
      {
        editor: false,
        type: 'text',
      },
      {
        type: 'numeric',
        numericFormat: {
          pattern: '0,0.[00]',
        },
      },
    ],
    rowHeaders: false,
    colWidths: [120, 100],
    colHeaders: ['Period', 'Price ($/mcf)'],

    afterChange: function (event) {
      let propertyId;
      switch (propName) {
        case 'gas_price':
          propertyId = referenceProperties.norwayGasPrice;
          break;
        case 'lng_price':
          propertyId = referenceProperties.asiaLNGPrice;
          break;
      }

      afterChangeEventHanlderForSingleInput(propertyId, this.getData(), ':');
    },
  });

  const gasPriceTable = new Handsontable(
    gasPriceElement,
    settings('gas_price'),
  );
  const lngPriceTable = new Handsontable(
    lngPriceElement,
    settings('lng_price'),
  );
  window.handsontableInstances.push(gasPriceTable);
  window.handsontableInstances.push(lngPriceTable);

  $('a[data-toggle="tab"][href="#gas-market"]').on('shown.bs.tab', (event) =>
    gasPriceTable.render(),
  );
  $('a[data-toggle="tab"][href="#gas-market"]').on('shown.bs.tab', (event) =>
    lngPriceTable.render(),
  );
};
