import Handsontable from 'handsontable';
import $ from 'jquery';
export default () => {
  const element = document.getElementById('calculated-well-schedule-table');
  $(element)
    .parent()
    .insertAfter($('#well-schedule-table').parent())
    .removeClass('hidden');

  if (!element) return;

  const inputData = JSON.parse($('#calculated-well-schedule-input-data').val());
  const settings = {
    data: inputData,
    rowHeaders: false,
    readOnly: true,
    disableVisualSelection: true,
    maxRows: JSCONSTANT.handsontable.wellScheduleRows,
  };

  let table = new Handsontable(element, settings);
  window.handsontableInstances.push(table);

  $('a[data-toggle="tab"][href="#development-schedule"]').on(
    'shown.bs.tab',
    (event) => table.render(),
  );
};
