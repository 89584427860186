import Handsontable from 'handsontable';
import $ from 'jquery';
import afterChangeEvent from './afterChangeEvent';

export default () => {
  let element = document.getElementById('japan-lng');
  let inputElement = $('#japan-lng-input-data');
  let propertyId = inputElement.data()['id'];
  let dataSource = JSON.parse(inputElement.val());

  new Handsontable(element, {
    data: dataSource,
    colHeaders: ['Year', 'US$/Mcf'],
    columns: [
      {
        type: 'text',
      },
      {
        type: 'numeric',
        numericFormat: {
          pattern: '0,0.[00]',
        },
      },
    ],
    afterChange: function () {
      let updatedData = this.getData();
      afterChangeEvent(propertyId, updatedData, ',');
    },
  });
};
