import Handsontable from 'handsontable';
import $ from 'jquery';
import afterChangeEventHanlderForMultipleInput from './afterChangeEventHanlderForMultipleInput';

export default (referenceProperties) => {
  const columns = [
    {
      data: 'year',
      type: 'text',
    },
    {
      data: 'serviceTariffPercent',
      type: 'numeric',
      numericFormat: {
        pattern: '0.0',
      },
    },
    {
      data: 'serviceTariffFat',
      type: 'numeric',
      numericFormat: {
        pattern: '0,0.[00]',
      },
    },
  ];

  const element = document.getElementById('service-tariff-table');
  if (!element) return;

  const inputData = JSON.parse($('#service-tariff-input-data').val());
  const settings = {
    data: inputData,
    columns,
    rowHeaders: false,
    colHeaders: ['Year', 'US$/bbl', 'FAt (0.00)'],

    afterChange(event) {
      const temp_result = {
        serviceTariffPercent: [],
        serviceTariffFat: [],
      };

      afterChangeEventHanlderForMultipleInput(
        temp_result,
        this.getSourceData(),
        referenceProperties,
      );
    },
  };

  let table = new Handsontable(element, settings);

  window.handsontableInstances.push(table);

  $('a[data-toggle="tab"][href="#financial-considerations"]').on(
    'shown.bs.tab',
    (event) => table.render(),
  );
};
