import $ from 'jquery';
import StaticGlobalPage from './static_global_page';
import CountryPage from './country_page';
import ManualInputPage from './manual_input_page';
import StaticFieldPropertyPage from './static_field_property_page';
import NewAssetPage from './new_asset_page';
import initClientDownloadLimit from './clients/userDownloadLimit';
import CountryInputPage from './country_input_page';
import JenkinsBuildPage from './jenkins_build_page';

export {
  StaticGlobalPage,
  CountryPage,
  ManualInputPage,
  StaticFieldPropertyPage,
  NewAssetPage,
  JenkinsBuildPage,
  initClientDownloadLimit,
  CountryInputPage,
};
