import $ from 'jquery';
export default function resetTypeCurve() {
  $('.btn-reset-type-curve').on('click', function () {
    if (!$(this).find('.save-button').hasClass('disabled-click')) {
      $(this).find('.save-button').addClass('disabled-click');
      const type = this.dataset.type;
      const marker = $(`.type-curve-${type}`).first();
      const field = marker.attr('field_id');
      const country = marker.attr('country_id');
      const data = new FormData();
      data.append('type', type);

      const asset_type = JSCONSTANT.country_have_block_manual_input.includes(
        parseInt(country),
      )
        ? 'block'
        : 'field';

      const _this = this;
      $.ajax({
        url: Routes.reset_type_curve_admin_country_asset_path(country, field, {
          asset_type,
        }),
        type: 'POST',
        data,
        processData: false,
        contentType: false,
        dataType: 'script',
        success() {
          $(_this).find('.save-button').removeClass('disabled-click');

          $('html, body').animate({ scrollTop: 0 }, 300);
        },
      });
    }
  });
}
