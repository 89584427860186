import Handsontable from 'handsontable';
import $ from 'jquery';
import afterChangeEventHanlderForSingleInput from './afterChangeEventHanlderForSingleInput';

export default (country_id, referenceProperties) => {
  let header;
  const element = document.getElementById('historical-gas-monetization-table');
  if (!element) return;

  const inputData = JSON.parse($('#historical-gas-monetization-data').val());

  if (country_id === JSCONSTANT.mozambique_id) {
    header = ['Year', 'Sales Percentage'];
  } else {
    header = ['Year', 'Prices'];
  }

  const settings = {
    data: inputData,
    colWidths: [150, 150],
    colHeaders: header,
    columns: [
      {
        editor: false,
      },
      {
        type: 'numeric',
        numericFormat: {
          pattern: '0,0.[00]',
        },
      },
    ],

    afterChange: function (event) {
      const propertyId = referenceProperties.historicalGasMonetization;

      afterChangeEventHanlderForSingleInput(propertyId, this.getData(), ':');
    },
  };

  let table = new Handsontable(element, settings);
  window.handsontableInstances.push(table);

  $('a[data-toggle="tab"][href="#financial-considerations"]').on(
    'shown.bs.tab',
    (event) => table.render(),
  );
};
