import Handsontable from 'handsontable';
import $ from 'jquery';
import afterChangeEventHanlderForMultipleInput from './afterChangeEventHanlderForMultipleInput';

export default (referenceProperties) => {
  const element = document.getElementById('historical-capex-2-table');
  if (!element) return;

  const inputData = JSON.parse($('#historical-capex-2-input-data').val());
  const settings = {
    data: inputData,
    columns: [
      {
        data: 'year',
        type: 'text',
      },
      {
        data: 'otherCapex',
        type: 'numeric',
        numericFormat: {
          pattern: '0,0.[00000]',
        },
      },
      {
        data: 'pipelines',
        type: 'numeric',
        numericFormat: {
          pattern: '0,0.[00000]',
        },
      },
      {
        data: 'facilities',
        type: 'numeric',
        numericFormat: {
          pattern: '0,0.[00000]',
        },
      },
    ],
    rowHeaders: false,
    colHeaders: [
      'Year',
      'Other Capex (US$MM)',
      'Pipelines (US$MM)',
      'Facilities (US$MM)',
    ],

    afterChange: function (event) {
      const temp_result = {
        otherCapex: [],
        pipelines: [],
        facilities: [],
      };

      afterChangeEventHanlderForMultipleInput(
        temp_result,
        this.getSourceData(),
        referenceProperties,
      );
    },
  };

  let table = new Handsontable(element, settings);
  window.handsontableInstances.push(table);

  $('a[data-toggle="tab"][href="#base-cost-inputs-in-nominal-terms"]').on(
    'shown.bs.tab',
    (event) => table.render(),
  );
};
