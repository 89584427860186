import $ from 'jquery';
import createBrentAndHenryHubPriceTable from './createBrentAndHenryHubPriceTable';
import createWtiOilPriceTable from './createWtiOilPriceTable';
import createGlobalGhgInputs from './createGlobalGhgInputs';
import submitFieldsForm from '../common/submitFieldsForm';
import addOpenPageListener from '../common/addOpenPageListener';
import setWindowOnErrorHandler from '../common/setWindowOnErrorHandler';
import createEuEtsPriceTable from './createEuEtsPriceTable';
import createTtfPriceTable from './createTtfPriceTable';
import createJapanLngTable from './createJapanLngTable';

export default () => {
  $('.ckeditor').each(function () {
    const editorConfig = $(this).data('ckeditor');
    CKEDITOR.replace(this.id, {
      customConfig: '/ckeditor/config.js',
      ...editorConfig,
    });
  });
  createBrentAndHenryHubPriceTable();
  createWtiOilPriceTable();
  createEuEtsPriceTable();
  createTtfPriceTable();
  createGlobalGhgInputs();
  createJapanLngTable();
  addOpenPageListener();
  setWindowOnErrorHandler();

  $('.global-button[data-type="submit"]').on('click', submitFieldsForm);
};
