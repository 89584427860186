import $ from 'jquery';
import hideAlert from '../common/hideAlert';
import showAlert from '../common/showAlert';

export default (ev) => {
  let btn = $(ev.target).parent();

  $.ajax({
    url: $(btn).data('url'),
    method: 'GET',
    error: function (response) {
      showAlert(response.responseJSON.message, 'danger');
      setTimeout(hideAlert, 3000, '.admin.alert');
    },
  });
};
