import Handsontable from 'handsontable';
import $ from 'jquery';
const RProductionOverrideProperties = [
  'yearly-manual-developed-production',
  'monthly-manual-developed-production',
  'yearly-manual-undeveloped-production',
  'monthly-manual-undeveloped-production',
];

const kebabToCamelize = (s) =>
  s.replace(/-./g, (word) => word[1].toUpperCase());

const afterChangeEventHanlderForSingleInput = (
  propertyId,
  updatedData,
  separator,
) => {
  const newValue = updatedData
    .map((subArray) => subArray.join(separator))
    .join(';');

  $(`#property_${propertyId}`).val(newValue);
};

export default (referenceProperties) => {
  for (const propName of RProductionOverrideProperties) {
    const element = document.querySelector(`#${propName}-table`);
    if (!element) continue;

    const inputData = $(`input[input_name="${propName}-input-data"]`)
      .val()
      .split(';')
      .map((row) => row.split(':'));
    let colHeaders, columns, colWidths;

    colHeaders = [
      'Period',
      'Oil (bbl/d)',
      'Gas (MMcf/d)',
      'Condensate (bbl/d)',
    ];
    columns = [
      {
        editor: false,
      },
      ...Array(3).fill({
        type: 'numeric',
        numericFormat: {
          pattern: '0,0.[00000]',
        },
      }),
    ];
    colWidths = [100, 150, 150, 150];

    const settings = {
      data: inputData,
      colWidths,
      colHeaders,
      columns,

      beforeChange: (changes, source) => {
        for (const change of changes) {
          if (change[1] == 0) change[3] = change[2];
        }
      },

      afterChange: function (event) {
        const propertyId = referenceProperties[kebabToCamelize(propName)];

        afterChangeEventHanlderForSingleInput(propertyId, this.getData(), ':');
      },
      maxRows: inputData.length,
    };

    let table = new Handsontable(element, settings);
    window.handsontableInstances.push(table);

    const tabName = propName.includes('undeveloped')
      ? 'manual-undeveloped-production'
      : 'manual-developed-production';

    $(`a[data-toggle="tab"][href="#${tabName}"]`).on('shown.bs.tab', (event) =>
      table.render(),
    );
  }
};
