import Handsontable from 'handsontable';
import $ from 'jquery';
export default () => {
  let colHeaders, columns, colWidths;
  let element = document.getElementById('facility-group-overrides-table');
  if (!element) return;

  const inputData = JSON.parse($('#facility-group-overrides-data').val());

  colWidths = [150, ...Array(8).fill(160)];
  colHeaders = [
    'Facility Group',
    'Gas - Low (MMcf/d)',
    'Gas - Base (MMcf/d)',
    'Gas - High (MMcf/d)',
    'Diesel - Low (MMcf/d)',
    'Diesel - Base (MMcf/d)',
    'Diesel - High (MMcf/d)',
    'Elec - Start Year',
    'Elec. %',
  ];
  columns = [
    {
      type: 'text',
      editor: false,
    },
    ...Array(6).fill({
      type: 'numeric',
      numericFormat: {
        pattern: '0,0.[00000000]',
      },
    }),
    {
      type: 'numeric',
    },
    {
      type: 'numeric',
      numericFormat: {
        pattern: '0,0.[00000000]',
      },
    },
  ];

  const settings = {
    data: inputData,
    colWidths: colWidths,
    colHeaders: colHeaders,
    columns: columns,

    afterChange: function (event) {
      let newData = this.getData()
        .map((subArray) => subArray.join(':'))
        .join(';');
      $(`#facility-group-overrides`).val(newData);
    },
  };

  let table = new Handsontable(element, settings);

  $('a[data-toggle="tab"][href="#ghg-asset-inputs"]').on(
    'shown.bs.tab',
    (event) => table.render(),
  );
};
