import Handsontable from 'handsontable';
import $ from 'jquery';
import afterChangeEventHanlderForSingleInput from './afterChangeEventHanlderForSingleInput';

export default (country_id, referenceProperties) => {
  const element = document.getElementById('country-gas-prices-table');
  const inputData = JSON.parse($('#country-gas-prices-input-data').val());
  let colHeaders, colWidths, columns;

  switch (country_id) {
    case JSCONSTANT.mozambique_id:
      {
        colHeaders = [
          'Year',
          'Domestic Market',
          'MzLNG 1',
          'MzLNG 2',
          'Coral FLNG',
          'Rovuma LNG 1',
          'Rovuma LNG 2',
          'Export Market',
        ];
        colWidths = [100, 150, 150, 150, 150, 150, 150, 150];
        columns = [
          {
            editor: false,
          },
          ...Array(7).fill({
            type: 'numeric',
            numericFormat: {
              pattern: '0,0.[00]',
            },
          }),
        ];
      }
      break;
    case JSCONSTANT.ghana_id:
      {
        colHeaders = ['Year', 'Domestic market 1', 'Domestic market 2'];
        colWidths = [100, 150, 150];
        columns = [
          {
            editor: false,
          },
          ...Array(2).fill({
            type: 'numeric',
            numericFormat: {
              pattern: '0,0.[00]',
            },
          }),
        ];
      }
      break;
    case JSCONSTANT.nigeria_id:
      {
        colHeaders = ['Year', 'LNG', 'Residential', 'Industrial'];
        colWidths = [100, 150, 150, 150];
        columns = [
          {
            editor: false,
          },
          ...Array(3).fill({
            type: 'numeric',
            numericFormat: {
              pattern: '0,0.[00]',
            },
          }),
        ];
      }
      break;
    default: {
      colHeaders = [
        'Year',
        'Domestic Market',
        'ALNG T1',
        'ALNG T2',
        'ALNG T3',
        'ALNG T4',
      ];
      colWidths = [100, 150, 150, 150, 150, 150];
      columns = [
        {
          editor: false,
        },
        ...Array(5).fill({
          type: 'numeric',
          numericFormat: {
            pattern: '0,0.[00]',
          },
        }),
      ];
    }
  }
  const settings = {
    data: inputData,
    colWidths,
    colHeaders,
    columns,

    afterChange: function (event) {
      const propertyId = referenceProperties.gasPrices;

      afterChangeEventHanlderForSingleInput(propertyId, this.getData(), ':');
    },
  };

  let table = new Handsontable(element, settings);
  window.handsontableInstances.push(table);

  $('a[data-toggle="tab"][href="#prices"]').on('shown.bs.tab', (event) =>
    table.render(),
  );
};
