import Handsontable from 'handsontable';
import $ from 'jquery';
import afterChangeEventHanlderForSingleInput from './afterChangeEventHanlderForSingleInput';

export default (country_id, referenceProperties) => {
  const element = document.getElementById('gas-pricing-transportation-table');
  let colHeaders, colWidths, columns;
  if (!element) return;

  if (country_id === JSCONSTANT.kazakhstan_id) {
    colHeaders = ['Year', 'Domestic', 'China Exports'];

    colWidths = [100, 100, 100];

    columns = [
      {
        editor: false,
      },
      {
        type: 'numeric',
        numericFormat: {
          pattern: '0,0.[00]',
        },
      },
      {
        type: 'numeric',
        numericFormat: {
          pattern: '0,0.[00]',
        },
      },
    ];
  } else {
    colHeaders = [
      'Year',
      'Domestic - Residential',
      'Domestic - Industrial',
      'European Exports',
      'FSU Exports',
    ];

    colWidths = [100, 100, 100, 100, 100];

    columns = [
      {
        editor: false,
      },
      {
        type: 'numeric',
        numericFormat: {
          pattern: '0,0.[00]',
        },
      },
      {
        type: 'numeric',
        numericFormat: {
          pattern: '0,0.[00]',
        },
      },
      {
        type: 'numeric',
        numericFormat: {
          pattern: '0,0.[00]',
        },
      },
      {
        type: 'numeric',
        numericFormat: {
          pattern: '0,0.[00]',
        },
      },
    ];
  }

  const inputData = JSON.parse($('#gas-pricing-transportation-data').val());

  const settings = {
    data: inputData,
    colWidths: colWidths,
    colHeaders: colHeaders,
    columns: columns,

    afterChange: function (event) {
      const propertyId = referenceProperties.gasPricingTransportation;

      afterChangeEventHanlderForSingleInput(propertyId, this.getData(), ':');
    },
  };

  let table = new Handsontable(element, settings);

  window.handsontableInstances.push(table);

  $('a[data-toggle="tab"][href="#financial-considerations"]').on(
    'shown.bs.tab',
    (event) => table.render(),
  );
};
