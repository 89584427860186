import Handsontable from 'handsontable';
import $ from 'jquery';
import afterChangeEventHanlderForSingleInput from './afterChangeEventHanlderForSingleInput';

export default (country_id, referenceProperties) => {
  const element = document.getElementById(
    'well-information-historical-drilling-table',
  );
  if (!element) return;

  let colHeaders, columns;

  if (
    JSCONSTANT.countryHasWellHistoricalDrilling5Columns.includes(country_id)
  ) {
    colHeaders = [
      'Year',
      'Development Well (Capex)',
      'Injection Well (Capex)',
      'Successful Exploration Well (Capex)',
      'Unsuccessful Exploration Well (Opex)',
      'Appraisal Well (Capex)',
    ];
    columns = [
      {
        editor: false,
      },
      {
        type: 'numeric',
      },
      {
        type: 'numeric',
      },
      {
        type: 'numeric',
      },
      {
        type: 'numeric',
      },
      {
        type: 'numeric',
      },
    ];
  } else if (JSCONSTANT.countryHasWellHistDrilling.includes(country_id)) {
    colHeaders = [
      'Year',
      'Development Well',
      'Injection Well',
      'Exploration Well',
      'Appraisal Well',
      'Other',
    ];
    columns = [
      {
        editor: false,
      },
      {
        type: 'numeric',
      },
      {
        type: 'numeric',
      },
      {
        type: 'numeric',
      },
      {
        type: 'numeric',
      },
      {
        type: 'numeric',
      },
    ];
  } else if (country_id == JSCONSTANT.suriname_id) {
    colHeaders = [
      'Year',
      'Development Well (Capex)',
      'Injection Well (Capex)',
      'Successful Exploration Well (Capex)',
      'Appraisal Well (Capex)',
      'Unsuccessful Exploration Well (Opex)',
    ];
    columns = [
      {
        editor: false,
      },
      {
        type: 'numeric',
      },
      {
        type: 'numeric',
      },
      {
        type: 'numeric',
      },
      {
        type: 'numeric',
      },
      {
        type: 'numeric',
      },
    ];
  } else if (country_id == JSCONSTANT.venezuela_id) {
    colHeaders = [
      'Year',
      'Production Well (Capex)',
      'Injection Well (Capex)',
      'Successful Exploration & Appraisal Well (Capex)',
      'Unsuccessful Exploration & Appraisal Well (Opex)',
    ];
    columns = [
      {
        editor: false,
      },
      {
        type: 'numeric',
      },
      {
        type: 'numeric',
      },
      {
        type: 'numeric',
      },
      {
        type: 'numeric',
      },
    ];
  } else {
    colHeaders = [
      'Year',
      'Development Well (Capex)',
      'Injection Well (Capex)',
      'Exploration Well (Capex)',
      'Appraisal Well (Capex)',
    ];
    columns = [
      {
        editor: false,
      },
      {
        type: 'numeric',
      },
      {
        type: 'numeric',
      },
      {
        type: 'numeric',
      },
      {
        type: 'numeric',
      },
    ];
  }

  const inputData = JSON.parse(
    $('#well-information-historical-drilling-input-data').val(),
  );
  const settings = {
    data: inputData,
    columns,
    colHeaders,
    afterChange: function (event) {
      const propertyId = referenceProperties.wellInformationHistoricalDrilling;
      afterChangeEventHanlderForSingleInput(propertyId, this.getData(), ':');
    },
  };

  let table = new Handsontable(element, settings);

  window.handsontableInstances.push(table);
  $('a[data-toggle="tab"][href="#development-schedule"]').on(
    'shown.bs.tab',
    (event) => table.render(),
  );
};
