import Handsontable from 'handsontable';
import $ from 'jquery';
import afterChangeEventHanlderForSingleInput from './afterChangeEventHanlderForSingleInput';

const valueFormulaForWeights = (data) => {
  return Number(
    (1 - parseFloat(data[0][1]) - parseFloat(data[1][1])).toFixed(2),
  );
};

export default (referenceProperties) => {
  const element = document.getElementById('service-tariff-weights-table');
  if (!element) return;

  const inputData = JSON.parse($('#service-tariff-weights-input-data').val());
  inputData.push(['Z (1-X-Y)', valueFormulaForWeights(inputData)]);

  let table;

  const settings = {
    data: inputData,
    columns: [
      {
        editor: false,
        type: 'text',
      },
      {
        type: 'numeric',
        numericFormat: {
          pattern: '0,0.[00]',
        },
      },
    ],
    rowHeaders: false,
    colHeaders: ['Weights', 'Value (0.00)'],

    afterChange: function (event) {
      const data = this.getData();

      data.pop();

      const propertyId = referenceProperties.serviceTariffWeights;

      afterChangeEventHanlderForSingleInput(propertyId, data, ':');

      inputData[2][1] = valueFormulaForWeights(data);

      if (table) {
        table.render();
      }
    },
  };

  table = new Handsontable(element, settings);
  window.handsontableInstances.push(table);

  $('a[data-toggle="tab"][href="#financial-considerations"]').on(
    'shown.bs.tab',
    (event) => table.render(),
  );
};
