const formatQueryString = (url, params) => {
  const query = new URLSearchParams(params).toString();
  return url + (query ? `?${query}` : '');
};

export const Routes = {
  maintenance_path: () => '/static/maintenance',
  customer_proprietary_downloads_path: ({ slug, ...restQuery }) => {
    const url = `/customer/proprietary-downloads/${slug}`;
    return formatQueryString(url, restQuery);
  },
  customer_download_path: (query) => {
    return formatQueryString('/customer/download', query);
  },
  customer_fetch_country_relative_data_path: () =>
    '/customer/fetch_country_relative_data',
  admin_country_asset_static_field_properties_path: (countryId, assetId) => {
    return `/admin/countries/${countryId}/assets/${assetId}/static_field_properties`;
  },
  admin_country_asset_static_block_properties_path: (countryId, assetId) => {
    return `/admin/countries/${countryId}/assets/${assetId}/static_block_properties`;
  },
  admin_country_asset_static_well_block_properties_path: (
    countryId,
    assetId
  ) => {
    return `/admin/countries/${countryId}/assets/${assetId}/static_well_block_properties`;
  },
  admin_country_asset_well_block_participations_path: (countryId, assetId) => {
    return `/admin/countries/${countryId}/assets/${assetId}/well_block_participations`;
  },
  import_csv_admin_country_path: (countryId) =>
    `/admin/countries/${countryId}/import_csv`,
  import_csv_admin_country_path: (countryId) =>
    `/admin/countries/${countryId}/import_csv`,
  import_type_curve_admin_country_asset_path: (countryId, assetId, params) => {
    const url = `/admin/countries/${countryId}/assets/${assetId}/import_type_curve`;
    return formatQueryString(url, params);
  },
  reset_type_curve_admin_country_asset_path: (countryId, assetId, params) => {
    const url = `/admin/countries/${countryId}/assets/${assetId}/reset_type_curve`;
    return formatQueryString(url, params);
  },
  reset_type_curve_admin_country_asset_path: (countryId, assetId, params) => {
    const url = `/admin/countries/${countryId}/assets/${assetId}/reset_type_curve`;
    return formatQueryString(url, params);
  },
};
