import Handsontable from 'handsontable';
import $ from 'jquery';
const afterChangeEventHanlderForSingleInput = (
  propertyId,
  updatedData,
  separator,
) => {
  const newValue = updatedData
    .filter((row) => row[0] !== null)
    .map((subArray) => subArray.join(separator))
    .join(';');

  $(`#property_${propertyId}`).val(newValue);
};

export default (country_id, referenceProperties) => {
  const element = document.getElementById('gas-production-table');
  if (!element) return;

  const inputData = JSON.parse($('#gas-production-input-data').val());
  let colHeaders, columns, colWidths;
  switch (country_id) {
    case JSCONSTANT.nigeria_id:
      {
        colHeaders = ['Year', 'LNG', 'Residential', 'Industrial'];
        columns = [
          {
            editor: false,
          },
          ...Array(3).fill({
            type: 'numeric',
            numericFormat: {
              pattern: '0,0.[00]',
            },
          }),
        ];
        colWidths = [100, 150, 150, 150];
      }
      break;
    case JSCONSTANT.ghana_id:
      {
        colHeaders = ['Year', 'Domestic Market 1', 'Domestic Market 2'];
        columns = [
          {
            editor: false,
          },
          ...Array(2).fill({
            type: 'numeric',
            numericFormat: {
              pattern: '0,0.[00]',
            },
          }),
        ];
        colWidths = [100, 150, 150];
      }
      break;
    case JSCONSTANT.guyana_id:
      {
        colHeaders = ['Year', 'Produced', 'Re-injected', 'Flared', 'Consumed'];

        columns = [
          {
            editor: false,
          },
          ...Array(4).fill({
            type: 'numeric',
            numericFormat: {
              pattern: '0,0.[00]',
            },
          }),
        ];

        colWidths = [100, 150, 150, 150, 150];
      }
      break;
    case JSCONSTANT.cyprus_id:
      {
        colHeaders = ['Year', 'Domestic Market', 'Export Market'];

        columns = [
          {
            editor: false,
          },
          ...Array(2).fill({
            type: 'numeric',
            numericFormat: {
              pattern: '0,0.[00]',
            },
          }),
        ];

        colWidths = [100, 200, 200];
      }
      break;
    case JSCONSTANT.israel_id:
      {
        colHeaders = [
          'Year',
          'Domestic Market',
          'Egypt',
          'Jordan',
          'LNG Contracted (MMcf/d)',
          'LNG Spot (MMcf/d)',
        ];

        columns = [
          {
            editor: false,
          },
          ...Array(5).fill({
            type: 'numeric',
            numericFormat: {
              pattern: '0,0.[00]',
            },
          }),
        ];

        colWidths = [100, 150, 150, 150, 150, 150];
      }
      break;
    case JSCONSTANT.jordan_id:
      {
        colHeaders = ['Year', 'Industrial (MMcf/d)'];

        columns = [
          {
            editor: false,
          },
          ...Array(1).fill({
            type: 'numeric',
            numericFormat: {
              pattern: '0,0.[00]',
            },
          }),
        ];

        colWidths = [100, 150];
      }
      break;
    case JSCONSTANT.egypt_id:
    case JSCONSTANT.saudi_arabia_id:
      {
        colHeaders = ['Year', 'PDP (MMcf/d)', 'Undeveloped (MMcfd)'];

        columns = [
          {
            editor: false,
          },
          ...Array(2).fill({
            type: 'numeric',
            numericFormat: {
              pattern: '0,0.[00]',
            },
          }),
        ];

        colWidths = [100, 200, 200];
      }
      break;
    case JSCONSTANT.australia_id:
    case JSCONSTANT.india_id:
    case JSCONSTANT.indonesia_id:
    case JSCONSTANT.malaysia_id:
    case JSCONSTANT.malaysia_thailand_jda_id:
    case JSCONSTANT.mozambique_id:
    case JSCONSTANT.papua_new_guinea_id:
    case JSCONSTANT.qatar_id:
    case JSCONSTANT.russia_id:
    case JSCONSTANT.romania_id:
    case JSCONSTANT.tanzania_id:
      {
        colHeaders = [
          'Year',
          'Domestic Market (mmcfd)',
          'LNG Contracted (MTPA)',
          'LNG Spot (MTPA)',
        ];

        columns = [
          {
            editor: false,
          },
          ...Array(3).fill({
            type: 'numeric',
            numericFormat: {
              pattern: '0,0.[00]',
            },
          }),
        ];

        colWidths = [100, 200, 200, 150];
      }
      break;
    case JSCONSTANT.morocco_id:
      {
        colHeaders = ['Year', 'Spain Exports', 'Domestic', 'Industrial'];

        columns = [
          {
            editor: false,
          },
          ...Array(3).fill({
            type: 'numeric',
            numericFormat: {
              pattern: '0,0.[00]',
            },
          }),
        ];

        colWidths = [100, 200, 200, 150];
      }
      break;
    case JSCONSTANT.azerbaijan_id:
    case JSCONSTANT.bangladesh_id:
    case JSCONSTANT.germany_id:
    case JSCONSTANT.iran_id:
    case JSCONSTANT.myanmar_id:
    case JSCONSTANT.kazakhstan_id:
    case JSCONSTANT.philippines_id:
    case JSCONSTANT.thailand_id:
    case JSCONSTANT.turkmenistan_id:
    case JSCONSTANT.uzbekistan_id:
    case JSCONSTANT.vietnam_id:
    case JSCONSTANT.pakistan_id:
    case JSCONSTANT.china_id:
      {
        colHeaders = ['Year', 'Gas Production'];
        columns = [
          {
            editor: false,
          },
          {
            type: 'numeric',
            numericFormat: {
              pattern: '0,0.[00]',
            },
          },
        ];
        colWidths = [100, 200];
      }
      break;
    case JSCONSTANT.brunei_id:
      {
        colHeaders = ['Year', 'LNG Production', 'Domestic Production'];
        columns = [
          {
            editor: false,
          },
          ...Array(2).fill({
            type: 'numeric',
            numericFormat: {
              pattern: '0,0.[00]',
            },
          }),
        ];
        colWidths = [100, 200, 200];
      }
      break;
    case JSCONSTANT.hungary_id:
      {
        colHeaders = ['Year', 'Domestic Market'];
        columns = [
          {
            editor: false,
          },
          ...Array(1).fill({
            type: 'numeric',
            numericFormat: {
              pattern: '0,0.[00]',
            },
          }),
        ];
        colWidths = [100, 200];
      }
      break;
    case JSCONSTANT.new_zealand_id:
      {
        colHeaders = ['Year', 'Produced', 'Re-injected', 'Flared'];

        columns = [
          {
            editor: false,
          },
          ...Array(3).fill({
            type: 'numeric',
            numericFormat: {
              pattern: '0,0.[00]',
            },
          }),
        ];
        colWidths = [100, 150, 150, 150, 150];
      }
      break;
    default: {
      colHeaders = [
        'Year',
        'Domestic Market',
        'ALNG T1',
        'ALNG T2',
        'ALNG T3',
        'ALNG T4',
      ];
      columns = [
        {
          editor: false,
        },
        ...Array(5).fill({
          type: 'numeric',
          numericFormat: {
            pattern: '0,0.[00]',
          },
        }),
      ];
      colWidths = [100, 150, 150, 150, 150, 150];
    }
  }

  const settings = {
    data: inputData,
    colWidths,
    colHeaders,
    columns,

    afterChange: function (event) {
      const propertyId = referenceProperties.gasProduction;

      afterChangeEventHanlderForSingleInput(propertyId, this.getData(), ':');
    },
  };

  let table = new Handsontable(element, settings);
  window.handsontableInstances.push(table);

  $('a[data-toggle="tab"][href="#production"]').on('shown.bs.tab', (event) =>
    table.render(),
  );
};
