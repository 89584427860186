import Handsontable from 'handsontable';
import $ from 'jquery';
import afterChangeEventHanlderForSingleInput from './afterChangeEventHanlderForSingleInput';

export default (referenceProperties) => {
  const element = document.getElementById('total-production-table');
  if (!element) return;

  const inputData = JSON.parse($('#total-production-input-data').val());
  const settings = {
    data: inputData,
    rowHeaders: false,
    colWidths: [50, 100, 100, 100],
    afterChange: function (event) {
      const propertyId = referenceProperties.totalProduction;
      afterChangeEventHanlderForSingleInput(propertyId, this.getData(), ',');
    },
  };

  let table = new Handsontable(element, settings);
  window.handsontableInstances.push(table);

  $('a[data-toggle="tab"][href="#total-production"]').on(
    'shown.bs.tab',
    (event) => table.render(),
  );
};
