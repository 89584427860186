import Handsontable from 'handsontable';
import $ from 'jquery';
import afterChangeEventHanlderForCostItems from './afterChangeEventHanlderForCostItems';

export default (referenceProperties) => {
  const element = document.getElementById('capex-gas-lng-table');

  if (!element) return;

  const inputData = JSON.parse($('#capex_gas_lng_input_data').val());

  const settings = {
    data: inputData,
    rowHeaders: false,
    afterChange: function (event) {
      const propertyId = referenceProperties.gasandLNGCapexItems;

      afterChangeEventHanlderForCostItems(propertyId, this.getData());
    },
  };

  let table = new Handsontable(element, settings);
  window.handsontableInstances.push(table);

  $('a[data-toggle="tab"][href="#base-cost-inputs-in-nominal-terms"]').on(
    'shown.bs.tab',
    (event) => table.render(),
  );
};
