import Handsontable from 'handsontable';
import $ from 'jquery';
import afterChangeEventHanlderForMultipleInput from './afterChangeEventHanlderForMultipleInput';

export default (referenceProperties) => {
  const element = document.getElementById('gas-prices-table');
  if (!element) return;

  const inputData = JSON.parse($('#gas-prices-input-data').val());
  const settings = {
    data: inputData,
    columns: [
      {
        data: 'year',
        type: 'text',
      },
      {
        data: 'austral',
        type: 'autocomplete',
        numericFormat: {
          pattern: '0,0.[00]',
        },
      },
      {
        data: 'cuyana',
        type: 'autocomplete',
        numericFormat: {
          pattern: '0,0.[00]',
        },
      },
      {
        data: 'golfoSanJorge',
        type: 'autocomplete',
        numericFormat: {
          pattern: '0,0.[00]',
        },
      },
      {
        data: 'neuquina',
        type: 'autocomplete',
        numericFormat: {
          pattern: '0,0.[00]',
        },
      },
      {
        data: 'noroeste',
        type: 'autocomplete',
        numericFormat: {
          pattern: '0,0.[00]',
        },
      },
    ],
    rowHeaders: false,
    colWidths: [70, 100, 100, 150, 100, 100],
    colHeaders: [
      'Year',
      'Austral',
      'Cuyana',
      'Golfo San Jorge',
      'Neuquina',
      'Noroeste',
    ],

    afterChange: function (event) {
      const temp_result = {
        austral: [],
        cuyana: [],
        golfoSanJorge: [],
        neuquina: [],
        noroeste: [],
      };
      afterChangeEventHanlderForMultipleInput(
        temp_result,
        this.getSourceData(),
        referenceProperties,
      );
    },
  };

  let table = new Handsontable(element, settings);
  window.handsontableInstances.push(table);

  $('a[data-toggle="tab"][href="#gas-prices"]').on('shown.bs.tab', (event) =>
    table.render(),
  );
};
