import $ from 'jquery';
import StaticFieldPropertyPage from '../static_field_property_page';
import '@selectize/selectize';

const submitNewAsset = (ev) => {
  const country = $('.country-id').attr('data-country-id');

  $('.new-asset')[0].submit();
};

export default () => {
  try {
    $('.new-asset .property-selectize').selectize({
      placeholder: I18n.t('js.customer.select'),
    });

    if ($('.new-asset .property-selectize').length > 0) {
      const country = $('.country-id').attr('data-country-id');
      const type = $('.hidden.asset-type').attr('data-asset-type');
      new StaticFieldPropertyPage(country, type, { new_asset: true });
    }

    $('.new-asset .save-button[data-type="submit"]').on(
      'click',
      submitNewAsset,
    );
  } catch (error) {
    console.log(error);
  }
};
