import $ from 'jquery';
import showAlert from '../common/showAlert';

export default (ev) => {
  let btn = $(ev.target).parent();

  if ($(btn).hasClass('disabled')) {
    return;
  }

  $(btn).addClass('disabled');

  $.ajax({
    url: $(btn).data('href'),
    method: 'POST',
    success: function (response) {
      $(btn).removeClass('disabled');
      showAlert(response.message, 'info');
    },
    error: function (response) {
      showAlert(response.responseJSON.message, 'danger');
    },
  });
};
