import Handsontable from 'handsontable';
import $ from 'jquery';
import afterChangeEventHanlderForSingleInput from './afterChangeEventHanlderForSingleInput';

export default (country_id, referenceProperties) => {
  const element = document.getElementById('fpso-field-table');

  if (!element) return;

  const [inputData, fields] = JSON.parse($('#fpso-field-input-data').val());
  const mergeCells = [];
  for (let i = 0; i < inputData[0].length; i += 2) {
    mergeCells.push({ row: 0, col: i, rowspan: 1, colspan: 2 });
  }

  const settings = {
    data: inputData,
    rowHeaders: true,
    mergeCells: mergeCells,
    className: 'htCenter',
    cells(row, column) {
      const cellMeta = {};
      if (row >= 2 && column % 2 == 0) {
        cellMeta.type = 'dropdown';
        cellMeta.source = fields;
      }

      return cellMeta;
    },
    afterChange: function (event) {
      const propertyId = referenceProperties.fPSOField;
      afterChangeEventHanlderForSingleInput(propertyId, this.getData(), ',');
    },
  };

  let table = new Handsontable(element, settings);
  window.handsontableInstances.push(table);

  $('a[data-toggle="tab"][href="#development-schedule"]').on(
    'shown.bs.tab',
    (event) => table.render(),
  );
};
