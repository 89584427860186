import submitFieldsForm from '../common/submitFieldsForm';
import addOpenPageListener from '../common/addOpenPageListener';
import setUnloadConfirmationOnFormChanged from '../country_page/setUnloadConfirmationOnFormChanged';
import setUnloadConfirmation from '../country_page/setUnloadConfirmation';
import ignoreUnload from '../country_page/ignoreUnload';
import setWindowOnErrorHandler from '../common/setWindowOnErrorHandler';
import hideAlert from '../common/hideAlert';
import showAssetStaticPropeties from '../common/showAssetStaticProperties';

import $ from 'jquery';
import '@selectize/selectize';
import 'ckeditor4';

export default (activeTab) => {
  addOpenPageListener();
  setWindowOnErrorHandler();
  $('.admin.alert.hidden').hide().removeClass('hidden');

  setUnloadConfirmationOnFormChanged($('form.admin.form'));
  ignoreUnload($('form.admin.form').find('.save-button[data-type="submit"]'));

  window.staticAssetPropertyConfig ??= {};
  if (activeTab.length > 0)
    window.staticAssetPropertyConfig.activeTab = activeTab;

  $(document).on('shown.bs.tab', 'a[data-toggle="tab"]', (e) => {
    const activeTab = $(e.target).attr('href');
    if (activeTab.length <= 0) return;

    const url = new URL(window.location.href);
    url.searchParams.set('active_tab', activeTab.slice(1));
    window.history.replaceState(null, null, url);
  });

  const handleUrlParameters = () => {
    if (!window.staticAssetPropertyConfig.pendingAsset) return;
    const url = new URL(window.location.href);
    url.searchParams.delete('active_tab');
    for (const assetType of ['field', 'block', 'well_block']) {
      url.searchParams.delete(`${assetType}_id`);
    }
    url.searchParams.set(
      `${window.staticAssetPropertyConfig.pendingAsset.type}_id`,
      window.staticAssetPropertyConfig.pendingAsset.id,
    );
    window.history.replaceState(null, null, url);
  };

  $('select.assets-selectize').selectize({
    onInitialize: function () {
      if (this.getValue() === '') return;

      setTimeout(
        () => this.$wrapper.parents('.box-search').find('.save-button').click(),
        200,
      );
    },
    onChange: function (value) {
      $(this['$input'])
        .parents('.box-search')
        .find('.save-button.filter-button[data-action="show-asset-fields"]')
        .data('asset', value);

      $(this['$input'])
        .parents('.box-search')
        .find(
          '.save-button.filter-button[data-action="show-well-block-participations"]',
        )
        .data('well_block', value);

      window.staticAssetPropertyConfig.pendingAsset = {
        type: this.$wrapper
          .parents('.box-search')
          .find('.save-button')
          .data('type'),
        id: value,
      };
    },
    maxOptions: 2000,
  });

  const clearCkeditor = () => {
    try {
      const ckEditors = Object.keys(CKEDITOR.instances);
      ckEditors.forEach((ckeditorInstanceName) => {
        CKEDITOR.instances[ckeditorInstanceName].destroy();
      });
    } catch (error) {
      console.log(error);
    }
  };

  $(document).on(
    'click',
    '.save-button.filter-button[data-action="show-asset-fields"]',
    function (ev) {
      hideAlert($('.alert').not('.admin'));
      const btn = $(this);
      const asset = btn.data('asset');
      const type = btn.data('type') || 'field';

      if (asset == '0') return;

      let load_form = true;

      // it is not the best way to check changes
      // but currently we setup 'onbeforeunload' if any change is made
      if (window.onbeforeunload != null) {
        load_form = confirm(
          'You have unsaved changes. Are you sure you want to load another field?',
        );
      }

      if (!load_form) return;

      $('.asset-fields-form').addClass('ready-to-load loading');
      const country_id = $('.country-id').attr('data-country-id');
      handleUrlParameters();
      showAssetStaticPropeties(
        country_id,
        type,
        asset,
        window.staticAssetPropertyConfig.activeTab,
      );
    },
  );

  $(document).on(
    'click',
    '.save-button.filter-button[data-action="show-well-block-participations"]',
    function (ev) {
      hideAlert($('.alert').not('.admin'));
      const btn = $(this);
      const asset = btn.data('well_block');
      const type = btn.data('type') || 'field';

      if (asset == '0') return;

      let load_form = true;

      // it is not the best way to check changes
      // but currently we setup 'onbeforeunload' if any change is made
      if (window.onbeforeunload != null) {
        load_form = confirm(
          'You have unsaved changes. Are you sure you want to load another well block?',
        );
      }

      if (!load_form) return;

      $('.asset-fields-form').addClass('ready-to-load loading');
      const country = $('.country-id').attr('data-country-id');

      handleUrlParameters();

      let url;

      url = Routes['admin_country_asset_well_block_participations_path'](
        country,
        asset,
      );
      clearCkeditor();

      const jQ = $;

      $.ajax({
        url: url,
        method: 'GET',
        success: function (field_form) {
          setUnloadConfirmation(false);
          const form = jQ('.row.asset-fields-form');
          jQ(form).html(field_form);
          jQ(form)
            .find('.save-button[data-type="submit"]')
            .on('click', submitFieldsForm);
          $('select.dependent_comp_assets').selectize({
            placeholder: I18n.t('js.customer.select'),
          });
          ignoreUnload(jQ(form).find('.save-button[data-type="submit"]'));
          setUnloadConfirmationOnFormChanged(jQ(form));
          $('.asset-fields-form').removeClass('ready-to-load loading');
          const type = $('.hidden.asset-type').attr('data-asset-type');
          Admin.StaticFieldPropertyPage(country, type);
        },
      });

      if (country == JSCONSTANT.peru_id) {
        const country_id = $('.country-id').attr('data-country-id');
        showAssetStaticPropeties(
          country_id,
          type,
          asset,
          window.staticAssetPropertyConfig.activeTab,
        );
      }
    },
  );
};
