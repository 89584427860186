import Handsontable from 'handsontable';
import $ from 'jquery';
import afterChangeEventHanlderForSingleInput from './afterChangeEventHanlderForSingleInput';

export default (referenceProperties) => {
  const element = document.getElementById('gas-monetization-and-tax-table');
  if (!element) return;

  const inputData = JSON.parse($('#gas-monetization-and-tax-data').val());

  const settings = {
    data: inputData,
    colWidths: [100, 200, 200, 250],
    colHeaders: [
      'Year',
      'Gas Monetization (%)',
      'Windfall Tax Rate (%)',
      'Corporate Income Tax Rate (%)',
    ],
    columns: [
      {
        editor: false,
        type: 'text',
      },
      ...Array(3).fill({
        type: 'numeric',
        numericFormat: {
          pattern: '0,0.[00]',
        },
      }),
    ],

    afterChange: function (event) {
      const propertyId = referenceProperties.gasMonetizationandTax;

      afterChangeEventHanlderForSingleInput(propertyId, this.getData(), ':');
    },
  };

  let table = new Handsontable(element, settings);
  window.handsontableInstances.push(table);

  $('a[data-toggle="tab"][href="#financial-considerations"]').on(
    'shown.bs.tab',
    (event) => table.render(),
  );
};
