import $ from 'jquery';
export default (ev) => {
  let btn = $('#run-import-companies-btn');
  let importCompanies = true;

  if ($(btn).hasClass('disabled')) {
    return;
  }

  // it is not the best way to check changes
  // but currently we setup 'onbeforeunload' if any change is made
  if (window.onbeforeunload != null) {
    importCompanies = confirm(
      'You have unsaved changes. Are you sure you want to run import companies?',
    );
  }

  if (!importCompanies) return;

  $('#confirm-import-companies-modal').modal();
};
