import Handsontable from 'handsontable';
import $ from 'jquery';
import afterChangeEventHanlderForCostItems from './afterChangeEventHanlderForCostItems';

export default (referenceProperties) => {
  const element = document.getElementById('opex-other-cost-items-table');
  if (!element) return;

  const inputData = JSON.parse($('#opex-cost-items-input-data').val());
  const settings = {
    data: inputData,
    rowHeaders: false,
    afterChange: function (event) {
      const propertyId = referenceProperties.otherCostItemsinRealTermsOpex;

      afterChangeEventHanlderForCostItems(propertyId, this.getData());
    },
  };

  let table = new Handsontable(element, settings);

  window.handsontableInstances.push(table);

  $('a[data-toggle="tab"][href="#historical-opex"]').on(
    'shown.bs.tab',
    (event) => table.render(),
  );
};
