import Handsontable from 'handsontable';
import $ from 'jquery';
import afterChangeEventHanlderForSingleInput from './afterChangeEventHanlderForSingleInput';

export default (country_id, referenceProperties) => {
  const element = document.getElementById(
    'split-undeveloped-gas-production-table',
  );
  if (!element) return;

  const inputData = JSON.parse(
    $('#split-undeveloped-gas-production-input-data').val(),
  );
  let colHeaders, columns, colWidths;

  switch (country_id) {
    case JSCONSTANT.mozambique_id:
      {
        colHeaders = [
          'Year',
          'Domestic',
          'MzLNG 1',
          'MzLNG 2',
          'Coral FLNG',
          'Rovuma LNG 1',
          'Rovuma LNG 2',
          'Export Market',
        ];
        columns = [
          {
            editor: false,
          },
          ...Array(7).fill({
            type: 'numeric',
            numericFormat: {
              pattern: '0,0',
            },
          }),
        ];
        colWidths = [100, 150, 150, 150, 150, 150, 150, 150];
      }
      break;
    case JSCONSTANT.tanzania_id:
      {
        colHeaders = [
          'Year',
          'Domestic Market',
          'Songo Songo',
          'Mnazi Bay',
          'TzLNG 1',
          'TzLNG 2',
          'Export Market',
        ];
        columns = [
          {
            editor: false,
          },
          ...Array(6).fill({
            type: 'numeric',
            numericFormat: {
              pattern: '0,0',
            },
          }),
        ];
        colWidths = [100, 150, 150, 150, 150, 150, 150];
      }
      break;
    default: {
      colHeaders = [
        'Year',
        'Domestic',
        'MzLNG 1',
        'MzLNG 2',
        'Coral FLNG',
        'Rovuma LNG 1',
        'Rovuma LNG 2',
        'Export Market',
      ];
      columns = [
        {
          editor: false,
        },
        ...Array(7).fill({
          type: 'numeric',
          numericFormat: {
            pattern: '0,0',
          },
        }),
      ];
      colWidths = [100, 150, 150, 150, 150, 150, 150, 150];
    }
  }

  const settings = {
    data: inputData,
    colWidths,
    colHeaders,
    columns,

    afterChange: function (event) {
      const propertyId = referenceProperties.splitUndevelopedGasProduction;

      afterChangeEventHanlderForSingleInput(propertyId, this.getData(), ':');
    },
  };

  let table = new Handsontable(element, settings);
  window.handsontableInstances.push(table);

  $('a[data-toggle="tab"][href="#production"]').on('shown.bs.tab', (event) =>
    table.render(),
  );
};
