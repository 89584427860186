import Handsontable from 'handsontable';
import $ from 'jquery';
import afterChangeEventHanlderForMultipleInput from './afterChangeEventHanlderForMultipleInput';

export default (country, referenceProperties) => {
  const element = document.getElementById('historical-capex-table');
  if (!element) return;

  const inputData = JSON.parse($('#historical-capex-input-data').val());
  const columns = [
    {
      data: 'year',
      type: 'text',
    },
    {
      data: 'averageWellCosts',
      type: 'numeric',
      numericFormat: {
        pattern: '0,0.[00000]',
      },
    },
  ];

  if (!JSCONSTANT.hide_subsea_cost_column.includes(country)) {
    columns.push({
      data: 'averageSubseaCostsperWell',
      type: 'numeric',
      numericFormat: {
        pattern: '0,0.[00000]',
      },
    });
  }
  const settings = {
    data: inputData,
    columns,
    rowHeaders: false,
    colHeaders: ['Year', 'Average Well Costs', 'Average Subsea per Well'],

    afterChange: function (event) {
      const temp_result = {
        averageWellCosts: [],
        averageSubseaCostsperWell: [],
        historicalWorkover: [],
        miscHistoricalCapex: [],
      };

      afterChangeEventHanlderForMultipleInput(
        temp_result,
        this.getSourceData(),
        referenceProperties,
      );
    },
  };

  if (JSCONSTANT.country_misc_historical_capex.includes(country)) {
    settings.colHeaders.pop();
    settings.colHeaders[1] = 'Well Cost';
    settings.columns.push({
      data: 'miscHistoricalCapex',
      type: 'numeric',
      numericFormat: {
        pattern: '0,0.[00000]',
      },
    });
    settings.colHeaders.push('Misc Capex');
  }

  if (country === '3') {
    //Mexico
    settings.columns.push({
      data: 'historicalWorkover',
      type: 'numeric',
    });
    settings.colHeaders.push('Historical Workover');
  }

  let table = new Handsontable(element, settings);
  window.handsontableInstances.push(table);

  $('a[data-toggle="tab"][href="#base-cost-inputs-in-nominal-terms"]').on(
    'shown.bs.tab',
    (event) => table.render(),
  );
};
