import $ from 'jquery';
export default (event) => {
  let exportButton = $(event.target).parent();
  if (exportButton.hasClass('disabled')) return;

  exportButton.addClass('disabled');
  Cookies.remove('csvFileDownloaded');

  let fileDownloadedInterval = setInterval(function () {
    if (Cookies.get('csvFileDownloaded')) {
      exportButton.removeClass('disabled');
      clearInterval(fileDownloadedInterval);
    }
  }, 3000);
};
