import Handsontable from 'handsontable';
import $ from 'jquery';
import afterChangeEventHanlderForSingleInput from './afterChangeEventHanlderForSingleInput';

export default (countryId, referenceProperties) => {
  let columns;
  const element = document.getElementById(
    'historical-well-schedule-override-table',
  );
  const inputData = JSON.parse(
    $('#historical-well-schedule-override-data').val(),
  );
  if (!element) return;

  if (
    JSCONSTANT.countryNoEditHistoricalWellScheduleOverride.includes(countryId)
  ) {
    columns = [
      {
        editor: false,
      },
      {
        editor: false,
      },
    ];
  } else {
    columns = [
      {
        type: 'text',
      },
      {
        type: 'numeric',
      },
    ];
  }

  const settings = {
    data: inputData,
    colWidths: [150, 150],
    colHeaders: ['Year', 'Well Count'],
    columns,
    afterChange: function (event) {
      const propertyId = referenceProperties.historicalWellScheduleOverride;
      afterChangeEventHanlderForSingleInput(propertyId, this.getData(), ':');
    },
  };

  let table = new Handsontable(element, settings);

  window.handsontableInstances.push(table);

  $('a[data-toggle="tab"][href="#development-schedule"]').on(
    'shown.bs.tab',
    (event) => table.render(),
  );
};
