import Handsontable from 'handsontable';
import $ from 'jquery';
import afterChangeEventHanlderForSingleInput from './afterChangeEventHanlderForSingleInput';

export default (referenceProperties) => {
  const element = document.getElementById('historical-opex-3-table');
  if (!element) return;

  const inputData = JSON.parse($('#historical-opex-3-input-data').val());
  const headers = JSON.parse($('#historical-opex-col-headers').val());
  const settings = {
    data: inputData,
    columns: [
      {
        type: 'text',
      },
      {
        type: 'numeric',
        numericFormat: {
          pattern: '0,0.[00]',
        },
      },
    ],
    rowHeaders: false,
    colHeaders: headers,
    afterChange: function (event) {
      const propertyId = referenceProperties.nonFPSOFixedOpex;

      afterChangeEventHanlderForSingleInput(propertyId, this.getData(), ':');
    },
  };

  let table = new Handsontable(element, settings);

  window.handsontableInstances.push(table);

  $('a[data-toggle="tab"][href="#historical-opex"]').on(
    'shown.bs.tab',
    (event) => table.render(),
  );
};
