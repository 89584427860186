import Handsontable from 'handsontable';
import $ from 'jquery';
import afterChangeEventHanlderForSingleInput from './afterChangeEventHanlderForSingleInput';

export default (country_id, referenceProperties) => {
  const element = document.getElementById('historical-drilling-table');
  let colHeaders, columns;

  if (JSCONSTANT.countryHasHistoricalDrilling4Column.includes(country_id)) {
    colHeaders = [
      'Year',
      'Production Well (Capex)',
      'Injection Well (Capex)',
      'Successful Exploration & Appraisal Well (Capex)',
      'Unsuccessful Exploration & Appraisal Well (Opex)',
    ];
    columns = [
      {
        editor: false,
      },
      ...Array(4).fill({
        type: 'text',
        placeholder: 'NA',
      }),
    ];
  } else {
    colHeaders = [
      'Year',
      'Production Well (Capex)',
      'Injection Well (Capex)',
      'Successful Exploration Well (Capex)',
      'Unsuccessful Exploration Well (Opex)',
      'Other Well (Capex)',
    ];
    columns = [
      {
        editor: false,
      },
      ...Array(5).fill({
        type: 'text',
        placeholder: 'NA',
      }),
    ];
  }
  const inputData = JSON.parse($('#historical-drilling-input-data').val());
  const settings = {
    data: inputData,
    columns,
    colHeaders,
    afterChange: function (event) {
      const propertyId = referenceProperties.historicalDrilling;
      afterChangeEventHanlderForSingleInput(propertyId, this.getData(), ':');
    },
  };

  let table = new Handsontable(element, settings);

  window.handsontableInstances.push(table);
  $('a[data-toggle="tab"][href="#base-cost-inputs-in-nominal-terms"]').on(
    'shown.bs.tab',
    (event) => table.render(),
  );
};
