import Handsontable from 'handsontable';
import $ from 'jquery';
export default () => {
  let element = document.getElementById(
    'purchased-electricity-consumption-split-table',
  );
  if (!element) return;

  const inputData = JSON.parse(
    $('#purchased-electricity-consumption-split-data').val(),
  );
  const settings = {
    data: inputData,
    colWidths: [50, ...Array(6).fill(110)],
    colHeaders: [
      'Year',
      'Natural Gas',
      'Diesel',
      'Crude',
      'Coal',
      'Biofuel',
      'Renewables',
    ],
    columns: [
      { type: 'numeric' },
      ...Array(6).fill({
        type: 'numeric',
        numericFormat: {
          pattern: '0,0.[00000000]',
        },
      }),
    ],

    afterChange: function (event) {
      let newData = this.getData()
        .map((subArray) => subArray.join(':'))
        .join(';');
      $(`#purchased-electricity-consumption-split`).val(newData);
    },
  };

  let table = new Handsontable(element, settings);

  $('a[data-toggle="tab"][href="#ghg-asset-inputs"]').on(
    'shown.bs.tab',
    (event) => table.render(),
  );
};
