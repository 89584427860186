import Handsontable from 'handsontable';
import $ from 'jquery';
import afterChangeEventHanlderForSingleInput from './afterChangeEventHanlderForSingleInput';

export default (referenceProperties) => {
  const element = document.getElementById('lng-contracted-price-table');
  const inputData = JSON.parse($('#lng-contracted-price-input-data').val());
  const marketValues = ['N Asia', 'SE Asia', 'Middle East', 'Europe', 'LatAm'];

  const settings = {
    data: inputData,
    colWidths: [250, 100],
    colHeaders: ['LNG Contracted Price', ''],
    cells(row, column) {
      const cellMeta = {};
      if (column === 0) {
        (cellMeta.type = 'text'), (cellMeta.editor = false);
      } else if (column === 1 && row === 0) {
        cellMeta.type = 'autocomplete';
        cellMeta.source = marketValues;
        cellMeta.placeholder = 'N/A';
      } else {
        cellMeta.type = 'numeric';
        cellMeta.numericFormat = {
          pattern: '0,0.[00]',
        };
      }

      return cellMeta;
    },

    afterChange: function (_event) {
      const propertyId = referenceProperties.lNGContractedPrice;

      afterChangeEventHanlderForSingleInput(propertyId, this.getData(), ':');
    },
  };

  let table = new Handsontable(element, settings);

  window.handsontableInstances.push(table);

  $('a[data-toggle="tab"][href="#financial-considerations"]').on(
    'shown.bs.tab',
    (event) => table.render(),
  );
};
