import Handsontable from 'handsontable';
import $ from 'jquery';
import afterChangeEventHanlderForSingleInput from './afterChangeEventHanlderForSingleInput';

export default (referenceProperties) => {
  const element = document.getElementById('pdp-decline-rates-table');
  if (!element) return;

  const inputData = JSON.parse($('#pdp-decline-rates-input-data').val());

  const settings = {
    data: inputData,
    colWidths: [100, 250],
    columns: [
      {
        type: 'numeric',
      },
      {
        type: 'numeric',
      },
    ],
    rowHeaders: false,
    colHeaders: ['Year (yyyy)', 'Decline (%)'],

    afterChange: function (event) {
      const propertyId = referenceProperties.pdpDeclineRates;

      afterChangeEventHanlderForSingleInput(propertyId, this.getData(), ':');
    },
  };

  let table = new Handsontable(element, settings);

  window.handsontableInstances.push(table);

  $('a[data-toggle="tab"][href="#development-schedule"]').on(
    'shown.bs.tab',
    (event) => table.render(),
  );
};
