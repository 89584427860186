import Handsontable from 'handsontable';
import $ from 'jquery';
import afterChangeEventHanlderForSingleInput from './afterChangeEventHanlderForSingleInput';

export default (referenceProperties) => {
  const element = document.getElementById('populate-market-table');
  if (!element) return;

  const inputData = JSON.parse($('#populate-market-input-data').val());

  const settings = {
    data: inputData,
    colWidths: [100, 100, 100, 100],
    colHeaders: ['Year', 'Domestic', 'Piped Exports', 'LNG'],
    columns: [
      {
        editor: false,
        type: 'text',
      },
      ...Array(3).fill({
        type: 'numeric',
        numericFormat: {
          pattern: '0,0.[00]',
        },
      }),
    ],

    afterChange: function (event) {
      const propertyId = referenceProperties.gasPriceMarket;

      afterChangeEventHanlderForSingleInput(propertyId, this.getData(), ':');
    },
  };

  let table = new Handsontable(element, settings);
  window.handsontableInstances.push(table);

  $('a[data-toggle="tab"][href="#financial-considerations"]').on(
    'shown.bs.tab',
    (event) => table.render(),
  );
};
